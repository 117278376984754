import { Box } from '@mui/material';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import styles from './MainLayout.module.scss';

const MainLayout = ({ title, isLoggedIn, children }) => {
  return (
    <>
      <Box className={styles.main}>
        <Navbar isLoggedIn={isLoggedIn}/>
        {children}
        <Footer />
      </Box>
    </>
  );
};

export default MainLayout;
