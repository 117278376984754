import { Box, Container, Grid, Typography } from '@mui/material';
import styles from './LoginSection.module.scss';
import LoginForm from '../login-form/LoginForm';
import contactInfographic from '../../assets/contact/contact-infographic.png';

const LoginSection = ({ setIsLoggedIn }) => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: { xs: '14rem 0', sm: '14rem 0', md: '14rem 0', lg: '14rem 0' },
      }}
    >
      <Grid
        container
        spacing={{ xs: 8, sm: 10, md: 0 }}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={10} sm={6} md={4}>
          <Box
            position="relative"
            width="100%"
            height={{ xs: 250, sm: 300, md: 280, lg: 320 }}
          >
            <img src={contactInfographic} alt="sales automation infographic" />
          </Box>
        </Grid>
        <Grid item xs={10} sm={8} md={5} lg={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
            }}
          >
            <Box className={styles.loginFormTitleBox}>
              <Typography variant="h4" fontWeight={700}>
                {`Login to your Account`}
              </Typography>
              <Typography variant="body1" fontWeight={300}>
                {`Login to proceed to Purchase`}
              </Typography>
            </Box>
            <LoginForm setIsLoggedIn={setIsLoggedIn}/>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginSection;
