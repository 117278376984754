import MainLayout from '../../components/main-layout/MainLayout';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import React from 'react';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import styles from './Pricing.module.scss';
import theme from '../../stylesheets/theme';
import axios from 'axios';
import { API_URL } from '../../utils/constants';

class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planLoaded: false,
      plans: [],
      basicPlan: '$50.00',
      proPlan: '$100.00',
      elitePlan: '$200.00',
      basicPlanDisc: false,
      proPlanDisc: false,
      elitePlanDisc: false,
      planToggle: false,
    };
  }

  updateSalesLightsDashboard = (event) => {
    var toggleVal = true;

    if (this.state.planToggle === true) {
      toggleVal = false;
    }

    if (toggleVal === false) {
      this.setState({
        basicPlan: this.getPlanPrice('basic_monthly_user'),
        proPlan: this.getPlanPrice('pro_monthly_user'),
        elitePlan: this.getPlanPrice('elite_monthly_user'),
        basicPlanDisc: false,
        proPlanDisc: false,
        elitePlanDisc: false,
      });
    } else {
      this.setState({
        basicPlan: this.getPlanPrice('basic_yearly_user'),
        proPlan: this.getPlanPrice('pro_yearly_user'),
        elitePlan: this.getPlanPrice('elite_yearly_user'),
        basicPlanDisc: true,
        proPlanDisc: true,
        elitePlanDisc: true,
      });
    }

    this.setState({ planToggle: toggleVal });
  };

  getPlanPrice = (licenseType) => {
    return this.convertToPrice(
      this.getPlanByLicenseType(licenseType)?.price_in_cents || 0,
    );
  };

  getPlanByLicenseType = (licenseType) => {
    const temp = [...this.state.plans];
    const found = temp.find(({ license_type }) => license_type === licenseType);
    return found;
  };

  convertToPrice = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(amount / 100);
  };

  componentDidMount = () => {
    axios.get(`${API_URL}/pricing/plans`).then((response) => {
      this.setState({
        planLoaded: true,
        plans: response.data,
        basicPlan: '$50.00',
        proPlan: '$100.00',
        elitePlan: '$200.00',
      });
    });
  };

  planSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `<path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `<path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  render() {
    return (
      <MainLayout title="SalesLights">
        <Box
          className={styles.main}
          sx={{
            gap: { xs: 0, sm: 0, md: '1rem', lg: '7rem' },
          }}
        >
          <Box
            className={styles.header}
            sx={{
              padding: { xs: 0, sm: 0, md: '6rem 0', lg: '11rem 0 4rem 0' },
            }}
          >
            <Box
              className={styles.whySaleslightSection}
              sx={{
                backgroundColor: theme.palette.secondary.main,
                padding: {
                  xs: '11rem 0 4rem 0',
                  sm: '4rem 0 4rem 0',
                  md: '4rem 0 4rem 0',
                  lg: '4rem 0 4rem 0',
                },
              }}
              py={10}
            >
              <Container
                className={styles.whySaleslightContainer}
                maxWidth="lg"
              >
                <Typography
                  variant="h4"
                  color="primary.dark"
                  align="center"
                  fontWeight={700}
                >
                  SalesLights - Licensing Tiers & Pricing
                </Typography>

                {this.state.planLoaded && (
                  <Box
                    sx={{
                      boxShadow: 3,
                      borderRadius: '17px 17px 17px 17px',
                    }}
                  >
                    <Grid container gridTemplateColumns="repeat(4, 1fr)">
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        sx={{
                          width: { xs: 280, sm: 250, md: '100%', lg: '100%' },
                          height: { xs: 280, sm: 150, md: '100%', lg: '100%' },
                        }}
                      >
                        <Box
                          className={[styles.whySaleslightItem]}
                          sx={{
                            borderRadius: {
                              xs: '17px 17px 0px 0px',
                              sm: '17px 17px 0px 0px',
                              md: '17px 0px 0px 17px',
                              lg: '17px 0px 0px 17px',
                              xl: '17px 0px 0px 17px',
                            },
                            alignItems: {
                              xs: 'center',
                              sm: 'center',
                              md: 'center',
                              lg: 'flex-start',
                            },
                            alignContent: {
                              xs: 'center',
                              sm: 'center',
                              md: 'center',
                              lg: 'flex-start',
                            },
                          }}
                        >
                          <Typography
                            variant="h5"
                            color="primary.dark"
                            fontWeight={500}
                          >
                            Pick your plan
                          </Typography>
                          <Typography variant="body1" fontWeight={300}>
                            Discounted price for
                            <br />
                            <b>Annual Plan</b>
                          </Typography>

                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography variant="body1" fontWeight={300}>
                              MONTHLY
                            </Typography>
                            <this.planSwitch
                              inputProps={{ 'aria-label': 'ant design' }}
                              checked={this.state.planToggle}
                              onChange={this.updateSalesLightsDashboard}
                            />
                            <Typography variant="body1" fontWeight={300}>
                              ANNUAL
                            </Typography>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        sx={{
                          width: { xs: 300, sm: 300, md: '100%', lg: '100%' },
                          height: { xs: 300, sm: 300, md: '100%', lg: '100%' },
                        }}
                      >
                        <Box
                          className={styles.whySaleslightItem}
                          sx={{
                            alignItems: {
                              xs: 'center',
                              sm: 'center',
                              md: 'center',
                              lg: 'flex-start',
                            },
                            alignContent: {
                              xs: 'center',
                              sm: 'center',
                              md: 'center',
                              lg: 'flex-start',
                            },
                          }}
                        >
                          <Typography
                            variant="h5"
                            color="primary.dark"
                            fontWeight={500}
                            fontSize={17}
                          >
                            BASIC PLAN - STARTER
                          </Typography>
                          <Typography
                            variant="h5"
                            color="primary"
                            fontWeight={600}
                            fontSize={40}
                          >
                            {this.state.basicPlan}
                          </Typography>

                          {this.state.basicPlanDisc ? (
                            [
                              <Typography variant="body" fontWeight={600}>
                                7% DISCOUNT
                              </Typography>,

                              <Typography variant="body1" fontWeight={300}>
                                PER YEAR
                              </Typography>,
                            ]
                          ) : (
                            <>
                              <Typography variant="h6" fontWeight={600}>
                                <span
                                  style={{ textDecoration: 'line-through' }}
                                >
                                  $199
                                </span>{' '}
                                NORMAL PRICE
                              </Typography>
                              <Typography variant="body1" fontWeight={300}>
                                PER MONTH
                              </Typography>
                            </>
                          )}

                          <Link to={`/pricing/basic-plan?subscription=${this.state.planToggle ? "yearly" : "monthly"}`}>
                            <br />
                            <Button
                              className={styles.selectPlanButton}
                              variant="contained"
                            >
                              SELECT PLAN
                            </Button>
                          </Link>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        sx={{
                          width: { xs: 300, sm: 300, md: '100%', lg: '100%' },
                          height: { xs: 300, sm: 300, md: '100%', lg: '100%' },
                        }}
                      >
                        <Box
                          className={styles.whySaleslightItem}
                          sx={{
                            alignItems: {
                              xs: 'center',
                              sm: 'center',
                              md: 'center',
                              lg: 'flex-start',
                            },
                            alignContent: {
                              xs: 'center',
                              sm: 'center',
                              md: 'center',
                              lg: 'flex-start',
                            },
                          }}
                        >
                          <Typography
                            variant="h5"
                            color="primary.dark"
                            fontWeight={500}
                            fontSize={17}
                          >
                            PRO PLAN - GROWTH
                          </Typography>
                          <Typography
                            variant="h5"
                            color="primary"
                            fontWeight={600}
                            fontSize={40}
                          >
                            {this.state.proPlan}
                          </Typography>

                          {this.state.proPlanDisc ? (
                            [
                              <Typography variant="body" fontWeight={600}>
                                10% DISCOUNT
                              </Typography>,

                              <Typography variant="body1" fontWeight={300}>
                                PER YEAR
                              </Typography>,
                            ]
                          ) : (
                            <>
                              <Typography variant="h6" fontWeight={600}>
                                <span
                                  style={{ textDecoration: 'line-through' }}
                                >
                                  $399
                                </span>{' '}
                                NORMAL PRICE
                              </Typography>
                              <Typography variant="body1" fontWeight={300}>
                                PER MONTH
                              </Typography>
                            </>
                          )}

                          <Link to={`/pricing/pro-plan?subscription=${this.state.planToggle ? "yearly" : "monthly"}`}>
                            <br />
                            <Button
                              className={styles.selectPlanButton}
                              variant="contained"
                            >
                              SELECT PLAN
                            </Button>
                          </Link>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        sx={{
                          width: { xs: 300, sm: 300, md: '100%', lg: '100%' },
                          height: { xs: 350, sm: 350, md: '100%', lg: '100%' },
                        }}
                      >
                        <Box
                          className={[styles.whySaleslightItem]}
                          sx={{
                            borderRadius: {
                              xs: '0px 0px 17px 17px',
                              sm: '0px 0px 17px 17px',
                              md: '0px 17px 17px 0px',
                              lg: '0px 17px 17px 0px',
                              xl: '0px 17px 17px 0px',
                            },
                            alignItems: {
                              xs: 'center',
                              sm: 'center',
                              md: 'center',
                              lg: 'flex-start',
                            },
                            alignContent: {
                              xs: 'center',
                              sm: 'center',
                              md: 'center',
                              lg: 'flex-start',
                            },
                          }}
                        >
                          <Typography
                            variant="h5"
                            color="primary.dark"
                            fontWeight={500}
                            fontSize={17}
                          >
                            ELITE PLAN - PREMIUM
                          </Typography>
                          <Typography
                            variant="h5"
                            color="primary"
                            fontWeight={600}
                            fontSize={40}
                          >
                            {this.state.elitePlan}
                          </Typography>

                          {this.state.elitePlanDisc ? (
                            [
                              <Typography variant="body" fontWeight={600}>
                                15% DISCOUNT
                              </Typography>,

                              <Typography variant="body1" fontWeight={300}>
                                PER YEAR
                              </Typography>,
                            ]
                          ) : (
                            <>
                              <Typography variant="h6" fontWeight={600}>
                                <span
                                  style={{ textDecoration: 'line-through' }}
                                >
                                  $599
                                </span>{' '}
                                NORMAL PRICE
                              </Typography>
                              <Typography variant="body1" fontWeight={300}>
                                PER MONTH
                              </Typography>
                            </>
                          )}

                          <Link to={`/pricing/elite-plan?subscription=${this.state.planToggle ? "yearly" : "monthly"}`}>
                            <br />
                            <Button
                              className={styles.selectPlanButton}
                              variant="contained"
                            >
                              SELECT PLAN
                            </Button>
                          </Link>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Container>
            </Box>
            {this.state.planLoaded && (
              <Box
                className={styles.whySaleslightPlanSection}
                sx={{ backgroundColor: '#fff' }}
                py={10}
              >
                <Container
                  className={styles.salesLightPlanContainer}
                  maxWidth="lg"
                >
                  <Grid
                    container
                    gridTemplateColumns="repeat(4, 1fr)"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={[
                          styles.salesLightPlanHeader,
                          styles.borderRadiusHeaderLeft,
                        ]}
                      >
                        <Typography
                          variant="h5"
                          color="white"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 10, md: 17, lg: 17 }}
                        >
                          Features
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box className={styles.salesLightPlanHeader}>
                        <Typography
                          variant="h5"
                          color="white"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 10, md: 17, lg: 17 }}
                        >
                          BASIC PLAN - STARTER
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box className={styles.salesLightPlanHeader}>
                        <Typography
                          variant="h5"
                          color="white"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 10, md: 17, lg: 17 }}
                        >
                          PRO PLAN - GROWTH
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={[
                          styles.salesLightPlanHeader,
                          styles.borderRadiusHeaderRight,
                        ]}
                      >
                        <Typography
                          variant="h5"
                          color="white"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 10, md: 17, lg: 17 }}
                        >
                          ELITE PLAN - PREMIUM
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>

                <Container
                  className={styles.salesLightPlanContainer}
                  maxWidth="lg"
                >
                  <Grid
                    container
                    gridTemplateColumns="repeat(4, 1fr)"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="body"
                          // fontWeight={300}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          <b>Advanced LinkedIn Lead</b>
                          <br />
                          <b>Analysis:</b> Deep learning-based
                          <br />
                          profiling for more accurate
                          <br />
                          targeting
                          <br />
                          <br />
                          &emsp;&#x2022;&nbsp;Posts, Reaction,
                          <br />
                          &emsp;&nbsp;&nbsp;Comments, & Replies
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          Unlimited View All Activity
                          <br />
                          Searches Daily
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          Unlimited View All Activity
                          <br />
                          Searches Daily
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          Unlimited View All Activity
                          <br />
                          Searches Daily
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>

                <Container
                  className={styles.salesLightPlanContainer}
                  maxWidth="lg"
                >
                  <Grid
                    container
                    gridTemplateColumns="repeat(4, 1fr)"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="body"
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          <b>Advanced LinkedIn Lead</b>
                          <br />
                          <b>Keyword Filtering:</b>
                          <br />
                          &emsp;&#x2022;&nbsp;Filter Relevant Content
                          <br />
                          &emsp;&nbsp;&nbsp;Based On Your Leads'
                          <br />
                          &emsp;&nbsp;&nbsp;Activity
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          5 Keyword Filtered Searches
                          <br />
                          Daily
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          Unlimited Keyword Filtered
                          <br />
                          Searches Daily
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          Unlimited Keyword Filtered
                          <br />
                          Searches Daily
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>

                <Container
                  className={styles.salesLightPlanContainer}
                  maxWidth="lg"
                >
                  <Grid
                    container
                    gridTemplateColumns="repeat(4, 1fr)"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="body"
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          <b>Content Automation using</b>
                          <br />
                          <b>Generative AI for Elite</b>
                          <br />
                          <b>Targeted Sales Messages at</b>
                          <br />
                          <b>Scale</b>
                          <br />
                          &emsp;&#x2022;&nbsp;Contextual Sales
                          <br />
                          &emsp;&nbsp;&nbsp;Message Creation with
                          <br />
                          &emsp;&nbsp;&nbsp;Advanced User Input
                          <br />
                          &emsp;&nbsp;&nbsp;Integration
                          <br />
                          &emsp;&#x2022;&nbsp;Natural Language Sales
                          <br />
                          &emsp;&nbsp;&nbsp;Content Creation: Text
                          <br />
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '700px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          10 Generated Sales <br />
                          Messages Daily
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          25 Generated Sales <br />
                          Messages Daily
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          50 Generated Sales <br />
                          Messages Daily
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>

                <Container
                  className={styles.salesLightPlanContainer}
                  maxWidth="lg"
                >
                  <Grid
                    container
                    gridTemplateColumns="repeat(4, 1fr)"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="body"
                          fontWeight={300}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          <b>Automated Drip Campaigns</b>
                          <br />
                          <b>& Sales Sequences (Highly</b>
                          <br />
                          <b>Customizable)</b>
                          <br />
                          &emsp;&#x2022;&nbsp;Office 365 & GSuite
                          <br />
                          &emsp;&nbsp;&nbsp;Email Marketing
                          <br />
                          &emsp;&nbsp;&nbsp;Integration
                          <br />
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          Coming Soon
                          <br />
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          Coming Soon
                          <br />
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          Coming Soon
                          <br />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>

                <Container
                  className={styles.salesLightPlanContainer}
                  maxWidth="lg"
                >
                  <Grid
                    container
                    gridTemplateColumns="repeat(4, 1fr)"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="body"
                          fontWeight={300}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          <b>Lead Generation: Powerful</b>
                          <br />
                          <b>Global Search</b>
                          <br />
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          Coming Soon
                          <br />
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          Coming Soon
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          Coming Soon
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>

                <Container
                  className={styles.salesLightPlanContainer}
                  maxWidth="lg"
                >
                  <Grid
                    container
                    gridTemplateColumns="repeat(4, 1fr)"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="body"
                          fontWeight={300}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          <b>Grandfathered into All 2024 New Features</b>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          Coming Soon
                          <br />
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          Coming Soon
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={6} md={3}>
                      <Box
                        className={styles.salesLightPlanItem}
                        minHeight={{
                          xs: '500px',
                          sm: '500px',
                          md: '500px',
                          lg: '250px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                        >
                          Coming Soon
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            )}
          </Box>
        </Box>
      </MainLayout>
    );
  }
}

export default Pricing;
