import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0164C7',
      contrastText: '#fff',
    },
    secondary: {
      main: '#EBF3FB',
      contrastText: '#fff',
    },
    tertiary: {
      main: '#8BC5FF',
      contrastText: '#fff',
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'unset',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 'unset !important',
        },
      },
    },
    MuiLoadingButtonLoading: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
  },
  typography: {
    fontFamily: "'Rubik'",
  },
});

export default theme;
