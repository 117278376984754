import MainLayout from '../../components/main-layout/MainLayout';
import styles from './EULA.module.scss';
import { Box } from '@mui/material';

const EULA = () => {
  return (
    <>
      <MainLayout title="SalesLights | EULA">
        <Box className={styles.main}>
          <div className={styles.container}>
            <h1>END USER LICENSE AGREEMENT</h1>
            <p>
              This End User License Agreement ("Agreement") is entered into
              between Saleslights Inc., a corporation registered under the laws
              of the State of Delaware, with its principal place of business at
              99 Wall Street #240, New York, NY 10005 ("Company," "we," "our,"
              or "us"), and the end user ("User," "you," or "your") of the
              software, applications, products, and services provided by the
              Company ("Services"). By accessing or using the Services, you
              acknowledge and agree to the terms and conditions outlined in this
              Agreement. If you do not agree with these terms, please refrain
              from using the Services.
            </p>
            <ul>
              <li>
                <h2>1. Grant of License:</h2>
                <p>
                  Subject to the terms of this Agreement, the Company grants you
                  a non-exclusive, non-transferable, revocable license to use
                  the Services for your personal or internal business purposes.
                  This license is limited to the scope and features explicitly
                  provided by the Company and does not include any right to
                  modify, distribute, sublicense, or reverse engineer the
                  Services.
                </p>
              </li>
              <li>
                <h2>2. Data Collection and Usage:</h2>
                <p>
                  You acknowledge and consent to the collection, storage, and
                  use of your personal and non-personal information as outlined
                  in our Privacy Policy. The Company reserves the right to
                  collect and process user data for various purposes, including
                  but not limited to improving the Services, marketing, and
                  analytics.
                </p>
              </li>
              <li>
                <h2>3. Sharing of Customer Information:</h2>
                <p>
                  By using the Services, you grant the Company the right to
                  share your customer information and data with its affiliates,
                  partners, and third-party service providers. This sharing of
                  information will be done within the legal framework of the
                  State of Delaware and applicable federal laws.
                </p>
              </li>
              <li>
                <h2>4. Intellectual Property:</h2>
                <p>
                  The Services, including but not limited to software, content,
                  trademarks, and logos, are the intellectual property of the
                  Company and its licensors. You agree not to reproduce,
                  distribute, modify, or create derivative works based on the
                  Services without the prior written consent of the Company.
                </p>
              </li>
              <li>
                <h2>5. Warranty Disclaimer:</h2>
                <p>
                  The Services are provided on an "as is" and "as available"
                  basis. The Company makes no warranties, whether express or
                  implied, regarding the accuracy, reliability, or suitability
                  of the Services for any purpose. You acknowledge that the use
                  of the Services is at your own risk.
                </p>
              </li>
              <li>
                <h2>6. Limitation of Liability:</h2>
                <p>
                  To the fullest extent permitted by law, the Company shall not
                  be liable for any direct, indirect, incidental, consequential,
                  or exemplary damages arising from the use of the Services or
                  any related actions, regardless of whether the Company has
                  been advised of the possibility of such damages.
                </p>
              </li>
              <li>
                <h2>7. Governing Law and Jurisdiction:</h2>
                <p>
                  This Agreement shall be governed by and construed in
                  accordance with the laws of the State of Delaware without
                  regard to its conflict of laws principles. Any legal action or
                  proceeding arising out of or relating to this Agreement shall
                  be subject to the exclusive jurisdiction of the state and
                  federal courts located in the State of Delaware.
                </p>
              </li>
              <li>
                <h2>8. Amendments and Modifications:</h2>
                <p>
                  The Company reserves the right to modify, amend, or update
                  this Agreement at any time. Your continued use of the Services
                  after such modifications constitutes your acceptance of the
                  updated Agreement.
                </p>
              </li>
              <li>
                <h2>9. Entire Agreement:</h2>
                <p>
                  This Agreement constitutes the entire understanding between
                  you and the Company with respect to the subject matter hereof
                  and supersedes any prior agreements or understandings, whether
                  written or oral.
                </p>
                <p>
                  By using the Services, you acknowledge that you have read,
                  understood, and agreed to be bound by the terms and conditions
                  of this Agreement.
                </p>
                <p>
                  If you have any questions or concerns about this Agreement,
                  please contact us at info@saleslights.com.
                </p>
                <p>Effective Date: [09-27-2023]</p>
                <ul>
                  <li>Saleslights Inc.</li>
                  <li>99 Wall Street #240</li>
                  <li>New York, NY 10005</li>
                </ul>
              </li>
            </ul>
          </div>
        </Box>
      </MainLayout>
    </>
  );
};

export default EULA;
