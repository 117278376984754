import { Link } from 'react-router-dom';
import { Box, Button, SwipeableDrawer, Typography } from '@mui/material';
import styles from './Sidebar.module.scss';

const Sidebar = ({ isOpen, setIsOpen }) => {
  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    >
      <Box
        className={styles.sidebar}
        height="100%"
        onClick={() => setIsOpen(false)}
        sx={{ width: { xs: '70vw', sm: '50vw' } }}
      >
        {/* <Link className={styles.linkText} to="#">
          <Typography variant="h6" color="primary.dark" fontSize={18}>
            SALES SOFTWARE
          </Typography>
        </Link>
        <Link className={styles.linkText} to="#">
          <Typography variant="h6" color="primary.dark" fontSize={18}>
            B2B LEAD GENERATION
          </Typography>
        </Link>
        <Link className={styles.linkText} to="#">
          <Typography variant="h6" color="primary.dark" fontSize={18}>
            EMAIL SOFTWARE
          </Typography>
        </Link> */}
        <Link to="/contact-us">
          <Button className={styles.contactButton} variant="contained">
            CONTACT US
          </Button>
        </Link>
        <Link className={styles.linkText} to="/signup">
          <Typography variant="h6" color="primary.dark" fontSize={18}>
            SIGNUP
          </Typography>
        </Link>

        <Link className={styles.linkText} to="/pricing">
          <Typography variant="h6" color="primary.dark" fontSize={18}>
            PRICING
          </Typography>
        </Link>
      </Box>
    </SwipeableDrawer>
  );
};

export default Sidebar;
