import { Box, Container, Grid, Typography } from '@mui/material';
import theme from '../../stylesheets/theme';
import styles from './Footer.module.scss';
import { Link } from 'react-router-dom';
import logoBrand from '../../assets/logo-brand.svg';
import facebook from '../../assets/facebook.png';
import instagram from '../../assets/instagram.png';
import linkedin from '../../assets/linkedin.png';

const Footer = () => {
  return (
    <>
      <Box
        className={styles.footer}
        sx={{ backgroundColor: theme.palette.secondary.main }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            justifyContent={{ xs: 'center', md: 'space-between' }}
            alignItems="start"
            spacing={{ xs: 4, sm: 6, md: 2 }}
          >
            <Grid item xs={12} sm={10} md={3}>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
              >
                <Box position="relative" width={180} height={50}>
                  <img
                    src={logoBrand}
                    alt="saleslight logo"
                    style={{ width: '100%' }}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    color="primary.dark"
                    fontWeight={500}
                  >
                    The Future of Sales:
                  </Typography>
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={400}
                  >
                    AI- powered Sales Automation Software
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    gap: '20px',
                  }}
                >
                  <Box position="relative" width={24} height={24}>
                    <img
                      src={facebook}
                      alt="saleslight logo"
                      style={{ width: '100%' }}
                    />
                  </Box>
                  <Box position="relative" width={24} height={24}>
                    <img
                      src={instagram}
                      alt="saleslight logo"
                      style={{ width: '100%' }}
                    />
                  </Box>
                  <Box position="relative" width={24} height={24}>
                    <img
                      src={linkedin}
                      alt="saleslight logo"
                      style={{ width: '100%' }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={5} md={2}>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
              >
                <Typography variant="h6" color="primary.dark" fontWeight={500}>
                  HOME
                </Typography>
                <Link className={styles.link} to="#">
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={300}
                  >
                    Sales Software
                  </Typography>
                </Link>
                <Link className={styles.link} to="#">
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={300}
                  >
                    B2B Lead Generation
                  </Typography>
                </Link>
                <Link className={styles.link} to="#">
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={300}
                  >
                    Email Software
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={6} sm={5} md={2} sx={{ order: { xs: 2, md: 1 } }}>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
              >
                <Typography variant="h6" color="primary.dark" fontWeight={500}>
                  SALES SOFTWARE
                </Typography>
                <Link className={styles.link} to="#">
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={300}
                  >
                    Sales Automation
                  </Typography>
                </Link>
                <Link className={styles.link} to="#">
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={300}
                  >
                    Content Automation
                  </Typography>
                </Link>
                <Link className={styles.link} to="#">
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={300}
                  >
                    Sales Engagement Platform
                  </Typography>
                </Link>
                <Link className={styles.link} to="#">
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={300}
                  >
                    Sales Intelligence
                  </Typography>
                </Link>
                <Link className={styles.link} to="#">
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={300}
                  >
                    AI Sales Partner
                  </Typography>
                </Link>
                <Link className={styles.link} to="#">
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={300}
                  >
                    CRM
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={6} sm={5} md={2} sx={{ order: { xs: 1, md: 2 } }}>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
              >
                <Typography variant="h6" color="primary.dark" fontWeight={500}>
                  B2B Lead Generation
                </Typography>
                <Link className={styles.link} to="#">
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={300}
                  >
                    Lead Generation
                  </Typography>
                </Link>
                <Link className={styles.link} to="#">
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={300}
                  >
                    LinkedIn Lead Generation
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={6} sm={5} md={2}>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
              >
                <Typography variant="h6" color="primary.dark" fontWeight={500}>
                  Email Software
                </Typography>
                <Link className={styles.link} to="#">
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={300}
                  >
                    Email Marketing
                  </Typography>
                </Link>
                <Link className={styles.link} to="#">
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={300}
                  >
                    Marketing Automation
                  </Typography>
                </Link>
                <Link className={styles.link} to="#">
                  <Typography
                    variant="body1"
                    color="primary.dark"
                    fontWeight={300}
                  >
                    Sales Sequencing Software
                  </Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        className={styles.footerBottom}
        sx={{ backgroundColor: theme.palette.tertiary.main }}
      >
        <Link className={styles.link} to="/terms-and-conditions">
          <Typography variant="body1" color="#fff" fontWeight={400}>
            Terms and Conditions
          </Typography>
        </Link>
        <Typography variant="body1" color="#fff" fontWeight={400}>
          |
        </Typography>
        <Link className={styles.link} to="/end-user-license-agreement">
          <Typography variant="body1" color="#fff" fontWeight={400}>
            End-User License Agreement
          </Typography>
        </Link>
        <Typography variant="body1" color="#fff" fontWeight={400}>
          |
        </Typography>
        <Link className={styles.link} to="/privacy-policy">
          <Typography variant="body1" color="#fff" fontWeight={400}>
            Privacy Policy
          </Typography>
        </Link>
        <Typography variant="body1" color="#fff" fontWeight={400}>
          |
        </Typography>
        <Link className={styles.link} to="/cookies-policy">
          <Typography variant="body1" color="#fff" fontWeight={400}>
            Cookies Policy
          </Typography>
        </Link>
      </Box>
    </>
  );
};

export default Footer;
