import { Button, Container, Typography } from '@mui/material';
import styles from './SignupSuccessSection.module.scss';

const SignupSuccessSection = () => {
  return (
    <Container
      className={styles.signupSuccessContainer}
      sx={{
        padding: { xs: '14rem 0', sm: '14rem 0', md: '14rem 0', lg: '15rem 0' },
      }}
    >
      <Typography
        // variant="h2"
        color="primary.dark"
        align="center"
        fontWeight={500}
        sx={{
          fontSize: { xs: '45px', sm: '50px', md: '50px', lg: '60px' },
        }}
      >
        Thank You For Signing Up!
      </Typography>

      <Typography
        variant="h4"
        align="center"
        sx={{
          padding: { xs: '0 5rem', sm: '0 5rem' },
          fontSize: { xs: '25px', sm: '25px', md: '30px', lg: '35px' },
        }}
      >
        Your <b>Demo Plan </b>Subscription starts today
      </Typography>

      <Typography
        variant="body1"
        align="center"
        sx={{ padding: { xs: '0 5rem', sm: '0 5rem' } }}
      >
        Increase sales automation and see your sales process change. Saleslights
        boosts ROI, conversions, and customer relationships. Ready to achieve
        sales excellence? Sales automation and the future is here!
      </Typography>
      <Button
        className={styles.downloadChromeExtensionBtn}
        variant="contained"
        onClick={() =>
          window.open(
            'https://chrome.google.com/webstore/detail/saleslights-inc/jllhlcfbocidmmnflpokgemkgfefpmpi',
            '_blank',
          )
        }
        sx={{
          fontSize: { xs: 'center', sm: 'center', md: 'center', lg: '16px' },
          width: { xs: '300px', sm: '300px', md: '400px', lg: '400px' },
        }}
      >
        {`DOWNLOAD CHROME EXTENSION`}
      </Button>
    </Container>
  );
};

export default SignupSuccessSection;
