import { Container, Typography } from '@mui/material';
import styles from './PricingCheckoutSuccessSection.module.scss';
import React from 'react';

class PricingCheckoutSuccessSection extends React.Component {
  constructor(props) {
    super(props);

    this.routes = localStorage.getItem('routes');
    this.planId = JSON.parse(this.routes).planId;
    this.planName = JSON.parse(this.routes).planName;
    this.planTitle = JSON.parse(this.routes).planTitle;
    this.planPrice = JSON.parse(this.routes).planPrice;

    this.routes = localStorage.getItem('user');
    this.email = JSON.parse(this.routes).email;
    this.affiliateUrl = `https://saleslights.postaffiliatepro.com/scripts/0mk3sm9gf0?AccountId=default1&TotalCost=${this.planPrice}&OrderID=${this.planName}&ProductID=${this.planName}`;
  }

  render() {
    console.log(this.affiliateUrl)
    return (
      <Container
        className={styles.pricingCheckoutSuccessContainer}
        sx={{
          padding: {
            xs: '14rem 0',
            sm: '14rem 0',
            md: '14rem 0',
            lg: '15rem 0',
          },
        }}
      >
        <img
          src={this.affiliateUrl}
          style={{
            "width": 1,
            "height": 1
          }}
        /> 
        <Typography
          color="primary.dark"
          align="center"
          fontWeight={500}
          sx={{
            fontSize: { xs: '45px', sm: '50px', md: '50px', lg: '60px' },
          }}
        >
          Thank You For Purchasing!
        </Typography>

        <Typography
          variant="h4"
          align="center"
          sx={{
            padding: { xs: '0 5rem', sm: '0 5rem' },
            fontSize: { xs: '25px', sm: '25px', md: '30px', lg: '35px' },
          }}
        >
          Your <b>{this.planTitle}</b> Subscription starts today
        </Typography>

        <Typography
          variant="body1"
          align="center"
          sx={{ padding: { xs: '0 5rem', sm: '0 5rem' } }}
        >
          Increase sales automation and see your sales process change.
          Saleslights boosts ROI, conversions, and customer relationships. Ready
          to achieve sales excellence? Sales automation and the future is here!
        </Typography>
      </Container>
    );
  }
}

export default PricingCheckoutSuccessSection;
