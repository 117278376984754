import MainLayout from '../../../components/main-layout/MainLayout';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import styles from './BasicPlanPricing.module.scss';
import theme from '../../../stylesheets/theme';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { API_URL } from '../../../utils/constants';

class BasicPlanPricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planLoaded: false,
      plans: [],
      basicPlan: '$50.00',
      basicPlanDiscounted: '$199.00',
      planToggle: false, // false: annual. true: monthly
      submitted: false,
    };
  }

  updateSalesLightsDashboard = () => {
    this.toggleSubscription(!this.state.planToggle)
  };

  toggleSubscription = (isAnnual) => {
    if (!isAnnual) {
      this.setState({
        basicPlan: this.getPlanPrice('basic_monthly_user'),
        basicPlanDiscounted: this.getOriginalPlanPrice('basic_monthly_user'),
      });
    } else {
      this.setState({
        basicPlan: this.getPlanPrice('basic_yearly_user'),
        basicPlanDiscounted: this.getOriginalPlanPrice('basic_yearly_user'),
      });
    }
    this.setState({ planToggle: isAnnual });
  }

  componentDidMount = () => {
    axios.get(`${API_URL}/pricing/plans`).then((response) => {
      this.setState({
        planLoaded: true,
        plans: response.data,
        elitePlan: this.getPlanPrice('basic_monthly_user'),
        elitePlanDiscounted: this.getOriginalPlanPrice('basic_monthly_user'),
      });
      this.handleQueryString();
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.planLoaded === true && prevState.planLoaded === false) {
      this.handleQueryString();
    }
    // if (window.location.search !== this.prevSearch) {
    //   this.handleQueryString();
    // }
  }

  handleQueryString() {
    const params = new URLSearchParams(window.location.search);
    const subscription = params.get('subscription') || '';
    this.toggleSubscription(subscription === "yearly");
    // this.prevSearch = window.location.search;
  }

  updateQueryString() {
     const params = new URLSearchParams(window.location.search);
     params.set('subscription', this.state.planToggle ? "yearly" : "monthly"); 
     const newUrl = `${window.location.pathname}?${params.toString()}`; 
     window.location.replace(newUrl);
  }

  getPlanPrice = (licenseType) => {
    return this.convertToPrice(
      this.getPlanByLicenseType(licenseType)?.price_in_cents || 0,
    );
  };

  getOriginalPlanPrice = (licenseType) => {
    return this.convertToPrice(
      this.getPlanByLicenseType(licenseType)?.original_price_in_cents || 0,
    );
  };

  getPlanByLicenseType = (licenseType) => {
    const temp = [...this.state.plans];
    const found = temp.find(({ license_type }) => license_type === licenseType);
    return found;
  };

  convertToPrice = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(amount / 100);
  };

  purchasebasicPlan = () => {
    // Check togglePlan
    var basicPlanItem = { license_type: 'basic_yearly_user' }; //Annual
    var basicPlanName = 'basic_yearly_user';
    var basicPlanId = 4;
    var basicPlanTitle = 'Basic Plan - Starter Yearly';

    if (this.state.planToggle === false) {
      basicPlanItem = { license_type: 'basic_monthly_user' }; //Monthly
      basicPlanName = 'basic_monthly_user';
      basicPlanId = 1;
      basicPlanTitle = 'Basic Plan - Starter Monthly';
    }

    // Save nextRoute to localStorage
    localStorage.setItem(
      'routes',
      JSON.stringify({
        nextRoute: `${API_URL}/pricing/create-checkout-session/`,
        planName: basicPlanName,
        planJSON: basicPlanItem,
        planId: basicPlanId,
        planTitle: basicPlanTitle,
        planPrice: this.getPlanPrice(basicPlanName).slice(1)
      }),
    );

    // Redirect user to login
    this.setState({ submitted: true });
  };

  planSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `<path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `<path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  render() {
    return (
      <React.Fragment>
        {this.state.submitted ? <Navigate to="/login" /> : null}

        <MainLayout title="SalesLights">
          <Box
            className={styles.main}
            sx={{
              gap: { xs: 0, sm: 0, md: '1rem', lg: '7rem' },
            }}
          >
            <Box
              className={styles.header}
              sx={{
                padding: { xs: 0, sm: 0, md: '6rem 0', lg: '11rem 0 4rem 0' },
              }}
            >
              <Box
                className={styles.whySaleslightSection}
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  padding: {
                    xs: '11rem 0 4rem 0',
                    sm: '4rem 0 4rem 0',
                    md: '4rem 0 4rem 0',
                    lg: '4rem 0 4rem 0',
                  },
                }}
                py={10}
              >
                <Container
                  className={styles.whySaleslightContainer}
                  maxWidth="lg"
                >
                  <Typography
                    variant="h4"
                    color="primary.dark"
                    align="center"
                    fontWeight={700}
                  >
                    SalesLights - Licensing Tiers & Pricing
                  </Typography>

                  <Box
                    sx={{
                      boxShadow: 3,
                      borderRadius: '17px 17px 17px 17px',
                    }}
                  >
                    <Grid container gridTemplateColumns="repeat(3, 1fr)">
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        sx={{
                          width: { xs: 280, sm: 250, md: '100%', lg: '100%' },
                          height: { xs: 280, sm: 150, md: '100%', lg: '100%' },
                        }}
                      >
                        <Box
                          className={[styles.whySaleslightItem]}
                          sx={{
                            borderRadius: {
                              xs: '17px 17px 0px 0px',
                              sm: '17px 17px 0px 0px',
                              md: '17px 0px 0px 17px',
                              lg: '17px 0px 0px 17px',
                              xl: '17px 0px 0px 17px',
                            },
                            alignItems: {
                              xs: 'center',
                              sm: 'center',
                              md: 'center',
                              lg: 'flex-start',
                            },
                            alignContent: {
                              xs: 'center',
                              sm: 'center',
                              md: 'center',
                              lg: 'flex-start',
                            },
                          }}
                        >
                          <Typography
                            variant="h5"
                            color="primary.dark"
                            fontWeight={500}
                          >
                            Pick your plan
                          </Typography>
                          <Typography variant="body1" fontWeight={300}>
                            Discounted price for
                            <br />
                            <b>Annual Plan</b>
                          </Typography>

                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography variant="body1" fontWeight={300}>
                              MONTHLY
                            </Typography>
                            <this.planSwitch
                              inputProps={{ 'aria-label': 'ant design' }}
                              checked={this.state.planToggle}
                              onChange={this.updateSalesLightsDashboard}
                            />
                            <Typography variant="body1" fontWeight={300}>
                              ANNUAL
                            </Typography>
                          </Stack>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={5}
                        sx={{
                          width: { xs: 280, sm: 250, md: '100%', lg: '100%' },
                          height: { xs: 280, sm: 150, md: '100%', lg: '100%' },
                        }}
                      >
                        <Box
                          className={[styles.whySaleslightItem]}
                          width="md"
                          sx={{
                            alignItems: {
                              xs: 'center',
                              sm: 'center',
                              md: 'center',
                              lg: 'flex-start',
                            },
                            alignContent: {
                              xs: 'center',
                              sm: 'center',
                              md: 'center',
                              lg: 'flex-start',
                            },
                          }}
                        >
                          <Typography
                            variant="h5"
                            color="primary.dark"
                            fontWeight={500}
                            fontSize={25}
                          >
                            BASIC PLAN - STARTER
                          </Typography>

                          <Typography
                            variant="h5"
                            color="primary"
                            fontWeight={600}
                            fontSize={50}
                            filter="grayscale(50%)"
                          >
                            {this.state.basicPlan}
                          </Typography>

                          <Typography
                            variant="body"
                            fontWeight={600}
                            fontSize={35}
                          >
                            <s>{this.state.basicPlanDiscounted}</s>
                          </Typography>

                          {this.state.planToggle ? (
                            <Typography variant="body1" fontWeight={300}>
                              PER YEAR
                            </Typography>
                          ) : (
                            <Typography variant="body1" fontWeight={300}>
                              PER MONTH
                            </Typography>
                          )}
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                          width: { xs: 280, sm: 250, md: '100%', lg: '100%' },
                          height: { xs: 280, sm: 150, md: '100%', lg: '100%' },
                        }}
                      >
                        <Box
                          className={[
                            styles.selectPlanBox,
                            styles.borderRadiusRight,
                          ]}
                          width="md"
                          sx={{
                            borderRadius: {
                              xs: '0px 0px 17px 17px',
                              sm: '0px 0px 17px 17px',
                              md: '0px 0px 17px 17px',
                              lg: '0px 0px 17px 17px',
                              xl: '0px 0px 17px 17px',
                            },
                            alignItems: {
                              xs: 'center',
                              sm: 'center',
                              md: 'center',
                              lg: 'flex-start',
                            },
                            alignContent: {
                              xs: 'center',
                              sm: 'center',
                              md: 'center',
                              lg: 'flex-start',
                            },
                          }}
                        >
                          <br />
                          <Button
                            className={styles.selectPlanButton}
                            variant="contained"
                            onClick={this.purchasebasicPlan}
                          >
                            SELECT PLAN
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>

                <Box
                  className={styles.whySaleslightPlanSection}
                  sx={{ backgroundColor: '#fff' }}
                  py={10}
                >
                  <Container
                    className={styles.salesLightPlanContainer}
                    maxWidth="lg"
                  >
                    <Grid
                      container
                      gridTemplateColumns="repeat(2, 1fr)"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={6} sm={6} md={6}>
                        <Box
                          className={[
                            styles.salesLightPlanHeader,
                            styles.borderRadiusHeader
                          ]}
                          width="md"
                        >
                          <Typography
                            variant="h5"
                            color="white"
                            fontWeight={500}
                            fontSize={{ xs: 12, sm: 14, md: 17, lg: 17 }}
                          >
                            What's Included
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>

                  <Container
                    className={styles.salesLightPlanContainer}
                    maxWidth="lg"
                  >
                    <Grid
                      container
                      gridTemplateColumns="repeat(2, 1fr)"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={6} sm={6} md={6}>
                        <Box
                          className={styles.salesLightPlanItem}
                          width="md"
                          minHeight={{
                            xs: '300px',
                            sm: '300px',
                            md: '500px',
                            lg: '250px',
                          }}
                          style={{ paddingTop: 20, paddingBottom: 20 }}
                        >
                          <div style={{
                              display: 'flex',
                              columnGap: 5,
                              alignItems: 'center',  
                            }}>
                            <div style={{ paddingTop: 6 }}>
                              <svg
                                aria-hidden="true"
                                class="e-font-icon-svg e-fas-check-circle"
                                viewBox="0 0 512 512"
                                fill="#00468B"
                                style={{ height: 20, width: 20, }}
                                xmlns="http://www.w3.org/2000/svg">
                                  <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
                              </svg>
                            </div>
                            <div style={{ lineHeight: '25px' }}>
                              100 AI Generated Sales Message Credits Per Month
                            </div>
                          </div>
                          
                          <div style={{
                              display: 'flex',
                              columnGap: 5,
                              alignItems: 'center',  
                            }}>
                            <div style={{ paddingTop: 6 }}>
                              <svg
                                aria-hidden="true"
                                class="e-font-icon-svg e-fas-check-circle"
                                viewBox="0 0 512 512"
                                fill="#00468B"
                                style={{ height: 20, width: 20, }}
                                xmlns="http://www.w3.org/2000/svg">
                                  <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
                              </svg>
                            </div>
                            <div style={{ lineHeight: '25px' }}>
                              Unlimited activity searches
                            </div>
                          </div>
                          
                          <div style={{
                              display: 'flex',
                              columnGap: 5,
                              alignItems: 'center',  
                            }}>
                            <div style={{ paddingTop: 6 }}>
                              <svg
                                aria-hidden="true"
                                class="e-font-icon-svg e-fas-check-circle"
                                viewBox="0 0 512 512"
                                fill="#00468B"
                                style={{ height: 20, width: 20, }}
                                xmlns="http://www.w3.org/2000/svg">
                                  <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
                              </svg>
                            </div>
                            <div style={{ lineHeight: '25px' }}>
                              Email sequencing
                            </div>
                          </div>
                          
                          <div style={{
                              display: 'flex',
                              columnGap: 5,
                              alignItems: 'center',  
                            }}>
                            <div style={{ paddingTop: 6 }}>
                              <svg
                                aria-hidden="true"
                                class="e-font-icon-svg e-fas-check-circle"
                                viewBox="0 0 512 512"
                                fill="#00468B"
                                style={{ height: 20, width: 20, }}
                                xmlns="http://www.w3.org/2000/svg">
                                  <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
                              </svg>
                            </div>
                            <div style={{ lineHeight: '25px' }}>
                              Outreach.io and Sendgrid integrations
                            </div>
                          </div>
                          
                          <div style={{
                              display: 'flex',
                              columnGap: 5,
                              alignItems: 'center',  
                            }}>
                            <div style={{ paddingTop: 6 }}>
                              <svg
                                aria-hidden="true"
                                class="e-font-icon-svg e-fas-check-circle"
                                viewBox="0 0 512 512"
                                fill="#00468B"
                                style={{ height: 20, width: 20, }}
                                xmlns="http://www.w3.org/2000/svg">
                                  <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
                              </svg>
                            </div>
                            <div style={{ lineHeight: '25px' }}>
                              Online 24/7 support
                            </div>
                          </div>
                          
                          <div style={{
                              display: 'flex',
                              columnGap: 5,
                              alignItems: 'center',  
                            }}>
                            <div style={{ paddingTop: 6 }}>
                              <svg
                                aria-hidden="true"
                                class="e-font-icon-svg e-fas-check-circle"
                                viewBox="0 0 512 512"
                                fill="#00468B"
                                style={{ height: 20, width: 20, }}
                                xmlns="http://www.w3.org/2000/svg">
                                  <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
                              </svg>
                            </div>
                            <div style={{ lineHeight: '25px' }}>
                              Full Sales Sequence Generation Costs 1 Credit
                            </div>
                          </div>
                          
                          <div style={{
                              display: 'flex',
                              columnGap: 5,
                              alignItems: 'center',  
                            }}>
                            <div style={{ paddingTop: 6 }}>
                              <svg
                                aria-hidden="true"
                                class="e-font-icon-svg e-fas-check-circle"
                                viewBox="0 0 512 512"
                                fill="#00468B"
                                style={{ height: 20, width: 20, }}
                                xmlns="http://www.w3.org/2000/svg">
                                  <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
                              </svg>
                            </div>
                            <div style={{ lineHeight: '25px' }}>
                              Initial Sales Message Generation Costs 1/2 Credit
                            </div>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </Box>
            </Box>
          </Box>
        </MainLayout>
      </React.Fragment>
    );
  }
}

export default BasicPlanPricing;
