import { Box, Button, CircularProgress, InputLabel, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from './LoginForm.module.scss';
import React from 'react';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { API_URL } from '../../utils/constants';

const WrapperComponent = ({ children }) => {
  const navigate = useNavigate();
  return React.cloneElement(children, { navigate });
};


class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorEmail: false,
      errorPassword: false,
      errorEmailMessage: '',
      errorPasswordMessage: '',
      submitted: false,
      checkoutMessageFlag: false,
      checkoutMessage: '',
      loading: false,
    };

    this.routes = localStorage.getItem('routes');
    this.nextRoute = JSON.parse(this.routes).nextRoute;
    this.planName = JSON.parse(this.routes).planName;
    this.planJSON = JSON.parse(this.routes).planJSON;
  }

  emailInputHandler = (event) => {
    this.setState({ email: event.target.value });
  };

  passwordInputHandler = (event) => {
    this.setState({ password: event.target.value });
  };

  purchasePlan = () => {
    localStorage.setItem(
      'user',
      JSON.stringify({
        email: this.state.email,
      }),
    );

    axios
    .post(this.nextRoute, {
      ...this.planJSON,
      email: this.state.email
    })
    .then((response) => {
      if (response.status === 200) {
        window.location.href = response.data.url;
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  loginUser = (event) => {
    event.preventDefault();

    this.setState({ errorEmail: false });
    this.setState({ errorPassword: false });

    if (this.state.email === undefined || this.state.email === '') {
      this.setState({ errorEmail: true });
      this.setState({ errorEmailMessage: 'Email is required' });
    }

    if (this.state.password === undefined || this.state.password === '') {
      this.setState({ errorPassword: true });
    }

    if (this.state.errorEmail === true || this.state.errorPassword === true) {
      return;
    }

    const email = this.state.email;
    const password = this.state.password;

    const requestJSON = {
      email: email,
      password: password,
    };
    this.setState({ loading: true })

    axios({
      url: `${API_URL}/user/signin`,
      method: 'POST',
      data: requestJSON,
    })
      .then((response) => {
        console.log(response.data)
        if (response.data.status !== 'OK') {
          if (response.data.field === 'email') {
            this.setState({ errorEmail: true });
            this.setState({ errorEmailMessage: response.data.err });
          } else if (response.data.field === 'password') {
            this.setState({ errorPassword: true });
            this.setState({ errorPasswordMessage: response.data.err });
          }
        } else if (response.data.status === 'OK') {
          this.props.setIsLoggedIn(true);
          sessionStorage.setItem("token", response.data.token);
          if (this.nextRoute) this.purchasePlan();
          else {
            this.props.navigate("/")
          }
        }
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        this.setState({ loading: false })
      });
  };

  render() {
    return (
      <React.Fragment>
        <form>
          <Box className={styles.loginForm}>
            <Box>
              <InputLabel shrink htmlFor="email">
                Email Address*
              </InputLabel>
              <TextField
                id="email"
                variant="outlined"
                placeholder="Email Address"
                fullWidth
                value={this.state.email}
                onChange={this.emailInputHandler}
                required
                error={this.state.errorEmail}
                helperText={
                  this.state.errorEmail ? this.state.errorEmailMessage : null
                }
                FormHelperTextProps={
                  this.state.errorEmail ? { style: { color: 'red' } } : null
                }
              />
            </Box>
            <Box className={styles.loginFormBox}>
              <InputLabel shrink htmlFor="password">
                Password*
              </InputLabel>
              <TextField
                id="password"
                variant="outlined"
                placeholder="Password"
                fullWidth
                type={'password'}
                value={this.state.password}
                onChange={this.passwordInputHandler}
                required
                error={this.state.errorPassword}
                helperText={
                  this.state.errorPassword
                    ? this.state.errorPasswordMessage
                    : null
                }
                FormHelperTextProps={
                  this.state.errorPassword ? { style: { color: 'red' } } : null
                }
              />
            </Box>
            <Button
              className={styles.loginButton}
              sx={{ width: { xs: '100%', sm: '226px' } }}
              variant="contained"
              fullWidth
              required
              disabled={this.state.loading}
              onClick={this.loginUser}
            >
              {
                this.state.loading ?
                <CircularProgress size={20} />
                : <>LOGIN</>
              }
            </Button>
            <Typography
              className={styles.signup}
              variant="body1"
              color="primary.dark"
              fontWeight={500}
              fontSize={14}
            >
              Don't have an account?
              <Link to="/signup"> Signup</Link>
            </Typography>

            {this.state.checkoutMessageFlag ? (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="success">{this.state.checkoutMessage}</Alert>
              </Stack>
            ) : null}
          </Box>
        </form>
      </React.Fragment>
    );
  }
}

// export default LoginForm;

export default (props) => (
  <WrapperComponent>
    <LoginForm {...props} />
  </WrapperComponent>
);
