import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logoBrand from '../../assets/logo-brand.svg';
import menu from '../../assets/menu.png';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import theme from '../../stylesheets/theme';
import styles from './Navbar.module.scss';
import Sidebar from '../sidebar/Sidebar';
import axios from 'axios';
import { API_URL } from '../../utils/constants';

const Navbar = ({ isLoggedIn }) => {
  const [colorChange, setColorchange] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);

  useEffect(() => {
    const changeNavbarColor = () => {
      if (window.scrollY >= 80) {
        setColorchange(true);
      } else {
        setColorchange(false);
      }
    };

    window.addEventListener('scroll', changeNavbarColor);
  }, []);

  const isAuthorized = () => {
    return isLoggedIn || (sessionStorage.getItem("token") || '').length > 0;
  }

  const createPortalSession = async () => {
    try {
      setIsNavigating(true);
      const token = sessionStorage.getItem("token");
      const response = await axios.get(
        `${API_URL}/pricing/create-portal-session`
        , {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            }
        });
      if (response.data.url) {
        window.location.href = response.data.url
      } else {
        alert("Cannot create portal session")
      }
    } catch (ex) {
      console.log(ex)
      alert("Cannot create portal session")
    } finally {
      setIsNavigating(false)
    }
  }

  return (
    <AppBar
      className={styles.navbar}
      position="fixed"
      sx={
        colorChange && {
          backgroundColor: '#fff',
          borderBottom: `4px solid ${theme.palette.primary.light}`,
          transition: '.5s all',
          boxShadow: '0px 0px 10px gray',
        }
      }
    >
      <Container className={styles.container} maxWidth="lg">
        <Link to="/">
          <Box
            className={styles.logo}
            width={{ xs: 140, sm: 190 }}
            height={{ xs: 38, sm: 60 }}
          >
            <img
              src={logoBrand}
              alt="saleslight logo"
              style={{ width: '100%' }}
            />
          </Box>
        </Link>
        <IconButton
          sx={{ display: { xs: 'block', md: 'none' } }}
          onClick={() => setIsSidebarOpen(true)}
        >
          <Box
            className={styles.hambugerMenu}
            sx={{ width: { xs: 36, sm: 42 }, height: { xs: 24, sm: 30 } }}
          >
            <img
              src={menu}
              alt="saleslight logo"
            />
          </Box>
        </IconButton>
        <Stack
          direction="row"
          spacing={{ md: 3, lg: 6 }}
          justifyContent="center"
          alignItems="center"
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          {
            isAuthorized() ?
            <>
              <Button className={styles.getStartedButton} variant="contained" onClick={createPortalSession}>
              {
                isNavigating ?
                <CircularProgress size={20} />
                : <>MANAGE BILLING</>
              }
              </Button>
            </>
            : <Link to="/signup">
              <Button className={styles.getStartedButton} variant="contained">
                GET STARTED - IT'S FREE
              </Button>
            </Link>
          }

          <Link to="/contact-us">
            <Button className={styles.contactButton} variant="contained">
              CONTACT US
            </Button>
          </Link>
          <Link to="/pricing">
            <Button className={styles.pricingButton} variant="outlined">
              PRICING
            </Button>
          </Link>
        </Stack>
      </Container>
      <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
    </AppBar>
  );
};

export default Navbar;
