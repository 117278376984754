import { Box, Button, InputLabel, TextField, Typography } from '@mui/material';
import styles from './SignupForm.module.scss';
import React from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { API_URL } from '../../utils/constants';

class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: '',
      fullname: '',
      email: '',
      password: '',
      confirmPassword: '',
      errorFullname: false,
      errorEmail: false,
      errorPassword: false,
      errorConfirmPassword: false,
      errorConfirmPasswordMessage: '',
      errorEmailMessage: '',
      submitted: false,
    };
  }

  fullnameInputHandler = (event) => {
    this.setState({ fullname: event.target.value });
  };

  emailInputHandler = (event) => {
    this.setState({ email: event.target.value });
  };

  passwordInputHandler = (event) => {
    this.setState({ password: event.target.value });
  };

  confirmPasswordInputHandler = (event) => {
    this.setState({ confirmPassword: event.target.value });
  };

  signupUser = (event) => {

    event.preventDefault();

    this.setState({ errorFullname: false });
    this.setState({ errorEmail: false });
    this.setState({ errorPassword: false });
    this.setState({ errorConfirmPassword: false });

    if (this.state.fullname === '' || { ...this.state.fullname } === '') {
      this.setState({ errorFullname: true });
    }

    if (this.state.email === undefined || this.state.email === '') {
      this.setState({ errorEmail: true });
      this.setState({ errorEmailMessage: 'Email is required' });
    }

    if (this.state.password === undefined || this.state.password === '') {
      this.setState({ errorPassword: true });
    }

    if (
      this.state.confirmPassword === undefined ||
      this.state.confirmPassword === ''
    ) {
      this.setState({ errorConfirmPassword: true });
      this.setState({
        errorConfirmPasswordMessage: 'Confirm Password is required',
      });
    } else {
      if (this.state.password !== this.state.confirmPassword) {
        this.setState({ errorConfirmPassword: true });
        this.setState({
          errorConfirmPasswordMessage: 'Please make sure passwords match',
        });
      }
    }

    if (
      this.state.errorFullname === true ||
      this.state.errorEmail === true ||
      this.state.errorPassword === true ||
      this.state.errorConfirmPassword === true
    ) {
      return;
    }

    const fullName = this.state.fullname;
    const email = this.state.email;
    const password = this.state.password;
    const demoUser = 'demo_user';

    const requestJSON = {
      fullname: fullName,
      email: email,
      password: password,
      license_type: demoUser,
    };

    axios({
      url: `${API_URL}/user/signupForm`,
      method: 'POST',
      data: requestJSON,
    })
      .then((response) => {
        if (response.status === 201) {
          this.setState({ submitted: true });
        } else {
          this.setState({ errorEmail: true });
          this.setState({ errorEmailMessage: response.data.err });

        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.submitted ? <Navigate to="/signup/signup-success" /> : null}

        <form>
          <Box className={styles.signupForm}>
            <Box>
              <InputLabel shrink htmlFor="fullname">
                Full Name*
              </InputLabel>
              <TextField
                id="fullname"
                variant="outlined"
                placeholder="Full Name"
                fullWidth
                value={this.state.fullname}
                onChange={this.fullnameInputHandler}
                required
                error={this.state.errorFullname}
                helperText={
                  this.state.errorFullname ? 'Fullname is required' : null
                }
                FormHelperTextProps={
                  this.state.errorFullname ? { style: { color: 'red' } } : null
                }
              />
            </Box>
            <Box>
              <InputLabel shrink htmlFor="email">
                Email Address*
              </InputLabel>
              <TextField
                id="email"
                variant="outlined"
                placeholder="Email Address"
                fullWidth
                value={this.state.email}
                onChange={this.emailInputHandler}
                required
                error={this.state.errorEmail}
                helperText={
                  this.state.errorEmail ? this.state.errorEmailMessage : null
                }
                FormHelperTextProps={
                  this.state.errorEmail ? { style: { color: 'red' } } : null
                }
              />
            </Box>
            <Box>
              <InputLabel shrink htmlFor="password">
                Password*
              </InputLabel>
              <TextField
                id="password"
                variant="outlined"
                placeholder="Password"
                fullWidth
                type={'password'}
                value={this.state.password}
                onChange={this.passwordInputHandler}
                required
                error={this.state.errorPassword}
                helperText={
                  this.state.errorPassword ? 'Password is required' : null
                }
                FormHelperTextProps={
                  this.state.errorPassword ? { style: { color: 'red' } } : null
                }
              />
            </Box>
            <Box>
              <InputLabel shrink htmlFor="confirmPassword">
                Confirm Password*
              </InputLabel>
              <TextField
                id="confirmPassword"
                variant="outlined"
                placeholder="Confirm Password"
                fullWidth
                type={'password'}
                value={this.state.confirmPassword}
                onChange={this.confirmPasswordInputHandler}
                required
                error={this.state.errorConfirmPassword}
                helperText={
                  this.state.errorConfirmPassword
                    ? this.state.errorConfirmPasswordMessage
                    : null
                }
                FormHelperTextProps={
                  this.state.errorConfirmPassword
                    ? { style: { color: 'red' } }
                    : null
                }
              />
            </Box>
            <Button
              className={styles.signupButton}
              sx={{ width: { xs: '100%', sm: '226px' } }}
              variant="contained"
              fullWidth
              required
              onClick={this.signupUser}
            >
              SIGNUP
            </Button>
            <Typography
              className={styles.signup}
              variant="body1"
              color="primary.dark"
              fontWeight={500}
              fontSize={14}
            >
              Already have an account?
              <Link to="/login"> Login</Link>
            </Typography>
          </Box>
        </form>
      </React.Fragment>
    );
  }
}

export default SignupForm;
