import MainLayout from '../../components/main-layout/MainLayout';
import styles from './PrivacyPolicy.module.scss';
import { Box, Container } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <>
      <MainLayout title="SalesLights | Contact Us">
        <Box className={styles.main}>
          <div className={styles.container}>
            <h1>Privacy Policy</h1>
            <h2>Effective Date: [09-27-2023]</h2>
            <ul>
              <li>
                <h2>1. Introduction</h2>
                <p>
                  Saleslights Inc. ("we," "us," or "our") is committed to
                  safeguarding your privacy and protecting your personal
                  information. This Privacy Policy outlines our practices
                  regarding the collection, use, sharing, and protection of your
                  information, as well as your rights concerning your data. By
                  accessing our website or using our services, you agree to the
                  terms outlined in this Privacy Policy.
                </p>
              </li>
              <li>
                <h2>2. Information</h2>
                <p>
                  We may collect various types of information, including but not
                  limited to:
                </p>
                <ul>
                  <li>
                    - Personal Information: Name, address, email, phone number,
                    and other contact details.
                  </li>
                  <li>
                    - Payment Information: Credit card details, billing address,
                    and transaction history.
                  </li>
                  <li>
                    - Usage Data: Information about how you interact with our
                    website and services.
                  </li>
                  <li>
                    - Cookies and Tracking Technologies: Information collected
                    through cookies, web beacons, and similar technologies.
                  </li>
                </ul>
              </li>
              <li>
                <h2>3. How We Use Your Information</h2>
                <p>We may use your information for the following purposes:</p>
                <ul>
                  <li>- Providing and improving our services.</li>
                  <li>
                    - Processing transactions and sending transactional
                    communications.
                  </li>
                  <li>- Sending you promotional offers and updates.</li>
                  <li>
                    - Understanding your preferences to enhance your user
                    experience.
                  </li>
                  <li>- Complying with legal obligations.</li>
                </ul>
                <ul>
                  <h2>
                    3.1 Saleslights Chrome Extension Privacy & Permissions{' '}
                  </h2>
                  <li>
                    <h2>3.1.1 Tabs</h2>
                    <p>
                      Saleslights SaaS tool to provide users with an organized
                      interface for managing multiple LinkedIn leads
                      simultaneously. Each tab represents a different lead,
                      allowing users to seamlessly switch between profiles,
                      reactions, and personality insights. This tab-based
                      approach enhances user experience by providing an
                      intuitive way to navigate and compare various leads' data
                      side by side.{' '}
                    </p>
                    <p>
                      Saleslights requires access to tab information to enhance
                      the user's browsing experience. We utilize the "tabs"
                      permission to gather data such as tab titles, URLs, and
                      identifiers, allowing us to provide contextual
                      functionality to the user. A primary function of our
                      extension is tab management. Users can refresh web pages
                      or perform other actions on tabs directly from our
                      interface. The "tabs" permission is imperative for
                      executing these commands accurately and efficiently. The
                      ability to refresh web pages is a fundamental feature of
                      our extension. By accessing the "tabs" permission, we can
                      reload specific tabs as requested by the user, ensuring
                      that they have access to the most up-to-date content
                      without manually refreshing each tab. Our extension
                      strictly adheres to the principle of least privilege. We
                      only collect and access tab-related data that is essential
                      for the extension's functionality. No sensitive or
                      personally identifiable information is ever collected or
                      stored.{' '}
                    </p>
                  </li>
                  <li>
                    <h2>3.1.2 Scripting </h2>
                    <p>
                      The scripting capability in the Saleslights SaaS tool
                      empowers businesses to customize and automate their sales
                      outreach with precision. By allowing users to create and
                      save predefined sales scripts, the tool ensures consistent
                      messaging across campaigns. This feature not only saves
                      time by eliminating the need to recreate messages but also
                      enhances the quality of interactions, leading to more
                      meaningful connections and conversions.
                    </p>
                    <p>
                      Salelights primary function is to gather specific data
                      from LinkedIn pages. This data can include user profiles,
                      job listings, or other publicly accessible information. To
                      achieve this, we employ custom scripts that interact with
                      the page's DOM (Document Object Model) to extract the
                      required information. Without the "scripting" permission,
                      our extension would be unable to automate the data
                      extraction process efficiently. This permission allows us
                      to execute scripts that traverse the page structure,
                      locate relevant elements, and extract data
                      programmatically. By utilizing custom scripts, we can
                      present the extracted data to users in a structured and
                      organized manner within our extension's user interface.
                      Data scraping is conducted with the explicit consent of
                      the user. Our extension is designed to scrape only
                      publicly available information on LinkedIn pages. We do
                      not access or collect private or restricted data, and we
                      adhere to Linkedin's Terms of Service.{' '}
                    </p>
                  </li>
                  <li>
                    <h2>3.1.3 Notifications</h2>
                    <p>
                      Notifications within the Saleslights SaaS tool serve as
                      timely alerts for users, keeping them informed about lead
                      interactions, profile updates, and campaign progress.
                      These notifications empower businesses to respond promptly
                      to any changes in a lead's activity, enabling timely and
                      relevant engagement. By staying updated, users can seize
                      opportunities to connect while the lead's interests are
                      still fresh, thereby maximizing the effectiveness of their
                      sales efforts.{' '}
                    </p>
                    <p>
                      Saleslights employs the "notifications" permission to
                      inform users about important events and updates within the
                      extension. This includes notifying users about completed
                      tasks, pending actions, or significant changes in the
                      extension's behavior. We leverage Chrome's native
                      notification system to communicate messages directly to
                      users. These notifications can include informative
                      messages, status updates, or alerts that are relevant to
                      their interaction with the extension. Our extension
                      respects user privacy by seeking explicit consent to send
                      notifications. Users are presented with clear, opt-in
                      choices to receive notifications. Users have the ability
                      to customize their notification preferences within the
                      extension's settings. We collect only the necessary data
                      to personalize notifications and improve their relevance.
                      Notifications are transmitted securely to users through
                      Chrome's built-in notification system, utilizing
                      industry-standard encryption protocols.{' '}
                    </p>
                  </li>
                  <li>
                    <h2>3.1.4 Host Permission Justification</h2>
                    <p>
                      Host permission is a crucial security feature in the
                      Saleslights SaaS tool that ensures controlled access to
                      user data. By requesting host permissions, the tool
                      ensures that only authorized individuals can access, view,
                      and manage the collected LinkedIn data. This safeguards
                      user privacy and prevents unauthorized access, maintaining
                      the confidentiality of both businesses' information and
                      the leads' data throughout the sales engagement process.{' '}
                    </p>
                    <p>
                      Saleslights is specifically designed to enhance the user
                      experience on certain websites, such as LinkedIn. By
                      utilizing the "host permission," we ensure that the
                      extension is active only on the intended sites, reducing
                      interference with unrelated web content. This allows us to
                      provide users with site-specific tools and features that
                      enhance their experience on these platforms. Our extension
                      is rigorously tested and verified to ensure that it only
                      operates on the designated websites, such as LinkedIn.
                      This verification process prevents unauthorized access to
                      other websites, guaranteeing user data privacy and
                      security. Our extension follows a strict principle of
                      least privilege, ensuring that user information remains
                      secure and confidential. It only accesses data on
                      supported websites that are necessary. No unauthorized
                      data is collected or processed. Our extension operates in
                      full compliance with the terms of service and policies of
                      the supported websites, such as LinkedIn.
                    </p>
                  </li>
                  <li>
                    <h2>3.1.5 Remote Code Justification </h2>
                    <p>
                      Incorporating remote code and leveraging The Saleslights
                      SaaS tool adds a layer of remote efficiency, as the entire
                      process of lead analysis, message creation, and campaign
                      execution can be managed from any location. This aligns
                      with the evolving landscape of remote work and empowers
                      businesses to maintain productive sales operations
                      irrespective of geographical constraints.
                    </p>
                    <p>
                      Saleslights leverages third-party CSS to dynamically style
                      web elements, enhancing the visual presentation and user
                      experience of the extension. By utilizing third-party CSS,
                      we can ensure that web elements are responsive and adapt
                      to different screen sizes and resolutions. Our extension
                      accesses third-party CSS styles from reputable sources
                      with established security measures. Our extension only
                      requests and applies the specific CSS styles required for
                      the user's customization. We do not collect or use
                      excessive data, reducing the potential for security
                      vulnerabilities. All CSS files are fetched via secure
                      HTTPS connections to prevent eavesdropping and data
                      tampering during transmission. We conduct routine security
                      audits and assessments to identify and rectify potential
                      vulnerabilities in the extension's codebase.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2>4. Sharing of Information</h2>
                <p>
                  We may share your information in the following circumstances:
                </p>
                <ul>
                  <li>
                    - With service providers who assist us in providing our
                    services.{' '}
                  </li>
                  <li>
                    - With our business partners for marketing and promotional
                    purposes.{' '}
                  </li>
                  <li>
                    - In response to legal requests or to protect our rights and
                    interests.{' '}
                  </li>
                  <li>
                    - In the event of a business merger, acquisition, or sale.{' '}
                  </li>
                </ul>
              </li>
              <li>
                <h2>5. Your Rights </h2>
                <p>
                  You have the following rights regarding your personal
                  information:
                </p>
                <ul>
                  <li>
                    - Access: You can request access to the personal information
                    we hold about you.
                  </li>
                  <li>
                    - Rectification: You can request corrections to inaccurate
                    or incomplete information.
                  </li>
                  <li>
                    - Erasure: You can request the deletion of your personal
                    information under certain conditions.
                  </li>
                  <li>
                    - Restriction: You can request the limitation of processing
                    in certain situations.
                  </li>
                  <li>
                    - Data Portability: You can request to receive your data in
                    a structured, commonly used, machine-readable format.{' '}
                  </li>
                  <li>
                    - Objection: You can object to the processing of your data
                    under certain circumstances.
                  </li>
                </ul>
              </li>
              <li>
                <h2>6. Security Measures </h2>
                <p>
                  We implement robust security measures to protect your personal
                  information from unauthorized access, disclosure, or
                  destruction. However, no method of transmission over the
                  internet or electronic storage is 100% secure.{' '}
                </p>
              </li>
              <li>
                <h2>7. Data Transfers</h2>
                <p>
                  Your personal information may be transferred to and processed
                  in countries outside of your own, including the United States.
                  We will take all necessary measures to ensure your data is
                  treated securely and in accordance with applicable laws.
                </p>
              </li>
              <li>
                <h2>8. Children's Privacy</h2>
                <p>
                  Our services are not intended for children under the age of
                  13. We do not knowingly collect personal information from
                  children. If you believe we have inadvertently collected such
                  information, please contact us.{' '}
                </p>
              </li>
              <li>
                <h2>9. Changes to this Privacy Policy</h2>
                <p>
                  We may update this Privacy Policy from time to time. Any
                  changes will be effective when posted on our website. It is
                  your responsibility to review this Privacy Policy
                  periodically.{' '}
                </p>
              </li>
              <li>
                <h2>10. Contact Us</h2>
                <p>
                  If you have any questions or concerns about this Privacy
                  Policy or our data practices, you can contact us at:
                </p>
                <ul>
                  <li>Saleslights Inc.</li>
                  <li>99 Wall Street #240</li>
                  <li>New York, NY 10005</li>
                  <li>Email: info@saleslights.com </li>
                </ul>
              </li>
              <li>
                <h2>11. Governing Law</h2>
                <p>
                  This Privacy Policy shall be governed by and construed in
                  accordance with the laws of the State of Delaware and the
                  General Data Protection Regulation (GDPR) as applicable.
                </p>
                <p>
                  By using our services, you acknowledge that you have read and
                  understood this Privacy Policy and consent to the collection,
                  use, and sharing of your information as described herein.{' '}
                </p>
              </li>
            </ul>
          </div>
        </Box>
      </MainLayout>
    </>
  );
};

export default PrivacyPolicy;
