import MainLayout from '../../components/main-layout/MainLayout';
import styles from './TOC.module.scss';
import { Box } from '@mui/material';

const TOC = () => {
  return (
    <>
      <MainLayout title="SalesLights | TOC">
        <Box className={styles.main}>
          <div className={styles.container}>
            <h1>TERMS & CONDITIONS</h1>
            <ul>
              <li>
                <h2>1. ACCEPTANCE OF TERMS</h2>
                <p>
                  These Terms & Conditions ("Agreement") govern your use of the
                  services provided by Saleslights Inc. ("Company," "we," "us,"
                  or "our"), a corporation registered at 99 Wall Street #240,
                  New York, NY 10005. By accessing our website, utilizing our
                  services, or engaging in any transaction with us, you hereby
                  agree to comply with and be bound by these Terms & Conditions.
                </p>
              </li>
              <li>
                <h2>2. PRIVACY AND DATA SHARING</h2>
                <ul>
                  <li>
                    a. Customer Information: While conducting business, the
                    Company may collect and process customer information,
                    including personal and transactional data. By using our
                    services, you consent to the collection, storage, and
                    processing of such data in compliance with our Privacy
                    Policy.
                  </li>
                  <li>
                    b. Data Sharing: The Company reserves the right to share
                    customer information with trusted third-party partners,
                    affiliates, and service providers for the purpose of
                    improving our services, conducting marketing activities, and
                    enhancing customer experience. The sharing of data shall
                    strictly adhere to the legal regulations of the State of
                    Delaware and applicable federal laws.
                  </li>
                </ul>
              </li>
              <li>
                <h2>3. INTELLECTUAL PROPERTY</h2>
                <ul>
                  <li>
                    a. Ownership: All content, materials, and intellectual
                    property on our website and related platforms, including but
                    not limited to text, graphics, logos, icons, images, audio
                    clips, digital downloads, and software, are the exclusive
                    property of Saleslights Inc. and are protected by
                    intellectual property laws.
                  </li>
                  <li>
                    b. Limited License: We grant you a limited, non-exclusive,
                    non-transferable license to access and use our services and
                    content for personal and non-commercial purposes. You shall
                    not reproduce, distribute, modify, create derivative works,
                    or exploit our intellectual property without prior written
                    consent from the Company.
                  </li>
                </ul>
              </li>
              <li>
                <h2>4. LIMITATION OF LIABILITY</h2>
                <p>
                  We may share your information in the following circumstances:
                </p>
                <ul>
                  <li>
                    a. Disclaimer: The Company's services are provided on an "as
                    is" and "as available" basis without any warranties,
                    expressed or implied. We do not guarantee the accuracy,
                    completeness, or reliability of any information or content
                    provided through our services.
                  </li>
                  <li>
                    b. Indemnification: You agree to indemnify and hold
                    Saleslights Inc., its officers, directors, employees, and
                    agents harmless from any claims, damages, liabilities, or
                    expenses arising from your use of our services, violation of
                    these Terms & Conditions, or infringement of any third-party
                    rights.
                  </li>
                </ul>
              </li>
              <li>
                <h2>5. GOVERNING LAW AND DISPUTE RESOLUTION</h2>
                <p>
                  This Agreement shall be governed by and construed in
                  accordance with the laws of the State of Delaware, without
                  regard to its conflict of laws principles. Any dispute arising
                  out of or in connection with this Agreement shall be subject
                  to the exclusive jurisdiction of the state and federal courts
                  located in the State of Delaware.
                </p>
              </li>
              <li>
                <h2>6. AMENDMENTS AND UPDATES</h2>
                <p>
                  Saleslights Inc. reserves the right to modify or amend these
                  Terms & Conditions at any time. Any changes shall be effective
                  upon posting on our website. It is your responsibility to
                  review this Agreement periodically to stay informed about any
                  updates.
                </p>
              </li>
              <li>
                <h2>7. ENTIRE AGREEMENT</h2>
                <p>
                  These Terms & Conditions constitute the entire agreement
                  between you and Saleslights Inc. regarding your use of our
                  services, superseding any prior agreements or understandings,
                  oral or written.
                </p>
                <p>
                  By using our services, you acknowledge that you have read,
                  understood, and agreed to these Terms & Conditions. If you do
                  not agree with any part of this Agreement, you must refrain
                  from using our services.
                </p>
                <p>
                  For inquiries or concerns, please contact us at
                  info@saleslights.com.{' '}
                </p>
                <p>Effective Date: [09-27-2023]</p>
                <ul>
                  <li>Saleslights Inc.</li>
                  <li>99 Wall Street #240</li>
                  <li>New York, NY 10005</li>
                </ul>
              </li>
            </ul>
          </div>
        </Box>
      </MainLayout>
    </>
  );
};

export default TOC;
