import MainLayout from '../../components/main-layout/MainLayout';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './LandingPage.module.scss';
import theme from '../../stylesheets/theme';
import ContactSection from '../../components/contact-section/ContactSection';
import ellipse from '../../assets/home/ellipse.png';
import ellipse1 from '../../assets/home/ellipse1.png';
import ellipse2 from '../../assets/home/ellipse2.png';
import ellipse3 from '../../assets/home/ellipse3.png';
import ellipse4 from '../../assets/home/ellipse4.png';
import ellipse5 from '../../assets/home/ellipse5.png';
import headerInfographic from '../../assets/home/header-infographic.png';
import salesMessageInfographic from '../../assets/home/sales-message-infographic.png';
import salesAutomationInfographic from '../../assets/home/sales-automation-infographic.png';
import group from '../../assets/home/group.png';
import group1 from '../../assets/home/group1.png';
import group2 from '../../assets/home/group2.png';
import group3 from '../../assets/home/group3.png';
import scalingRoiInfographic from '../../assets/home/scaling-roi-infographic.png';
import salesOutreachInfographic from '../../assets/home/sales-outreach-infographic.png';
import contextualMessagingInfographic from '../../assets/home/contextual-messaging-infographic.png';
import timeEfficientIcon from '../../assets/home/time-efficient-icon.png';
import increaseEfficiencyIcon from '../../assets/home/increase-efficiency-icon.png';
import personalizeIcon from '../../assets/home/personalize-icon.png';
import consistencyCommunicationIcon from '../../assets/home/consistency-communication-icon.png';
import React from 'react';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    localStorage.setItem(
      'routes',
      JSON.stringify({
        nextRoute: '',
        planName: '',
        planJSON: '',
        planId: '',
        planTitle: '',
      }),
    );

    localStorage.setItem(
      'user',
      JSON.stringify({
        email: '',
      }),
    );
  }

  render() {
    return (
      <MainLayout title="SalesLights">
        <Box className={styles.main}>
          <Box
            className={styles.header}
            sx={{
              padding: {
                xs: '8rem 0 5rem 0',
                sm: '9rem 0',
                md: '10rem 0',
                lg: '11rem 0',
              },
            }}
          >
            <Box
              position="absolute"
              width={{ xs: 200, sm: 250, md: 220, lg: 270 }}
              height={{ xs: 120, sm: 130, md: 120, lg: 160 }}
              sx={{ top: 0, left: { xs: '8%', sm: '3%', md: '6%', lg: '13%' } }}
            >
              <img
                src={ellipse}
                alt="saleslight infographic"
              />
            </Box>
            <Box
              position="absolute"
              width={{ xs: 30, sm: 40, md: 45, lg: 50 }}
              height={{ xs: 30, sm: 40, md: 45, lg: 50 }}
              sx={{
                top: { xs: '58%', sm: '48%', md: '18%', lg: '15%' },
                right: { xs: '22%', sm: '28%', md: '3%', lg: '9%' },
              }}
            >
              <img
                src={ellipse1}
                alt="saleslight infographic"
              />
            </Box>
            <Box
              position="absolute"
              width={{ xs: 30, sm: 40, md: 45, lg: 50 }}
              height={{ xs: 30, sm: 40, md: 45, lg: 50 }}
              sx={{
                bottom: { xs: '5%', sm: '9%', md: '11%', lg: '12%' },
                left: { xs: '9%', sm: '15%', md: '30%' },
              }}
            >
              <img
                src={ellipse2}
                alt="saleslight infographic"
              />
            </Box>
            <Container className={styles.headerContainer} maxWidth="lg">
              <Grid
                container
                spacing={{ xs: 8, sm: 10, md: 0 }}
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  className={styles.headerInfoSection}
                  item
                  xs={12}
                  md={4.5}
                  lg={4}
                >
                  <Typography
                    variant="h1"
                    color="primary.dark"
                    fontWeight={700}
                    sx={{ fontSize: { xs: 28, sm: 42, md: 32, lg: 34 } }}
                  >
                    The Future of Sales: AI-powered Sales Automation Software
                  </Typography>
                  <Typography
                    variant="h1"
                    color="primary.dark"
                    fontWeight={500}
                    fontSize={{ xs: 18, sm: 22, md: 18 }}
                  >
                    The Ultimate Sales Automation Software: Saleslights
                  </Typography>
                  <Typography
                    variant="body1"
                    fontWeight={300}
                    fontSize={{ xs: 14, sm: 22, md: 18 }}
                  >
                    {`Are you sick of spending hours researching your LinkedIn
                  leads' social media posts, profiles, and comments to write
                  tailored sales messages?`}
                  </Typography>
                  <Button
                    className={styles.headerLearnButton}
                    variant="contained"
                    onClick={() =>
                      window.open(
                        'https://chrome.google.com/webstore/detail/saleslights-inc/jllhlcfbocidmmnflpokgemkgfefpmpi',
                        '_blank',
                      )
                    }
                  >
                    Download Chrome Extension
                  </Button>
                </Grid>
                <Grid
                  className={styles.headerInfographicSection}
                  item
                  xs={12}
                  md={6}
                  lg={7}
                >
                  <Box
                    position="relative"
                    width={{ xs: 400, sm: 600, md: 800, lg: 700 }}
                    height={{ xs: 230, sm: 340, md: 320, lg: 450 }}
                  >
                    <img
                      src={headerInfographic}
                      alt="saleslight infographic"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Container
              className={styles.headerContainer}
              style={{ marginTop: 50, marginBottom: -100 }}
              maxWidth="lg"
            >
              <Button
                className={styles.ytAcademy}
                variant="contained"
                onClick={() =>
                  window.open(
                    'https://www.youtube.com/channel/UC1Ax9UOZMmYMnmWENvPwNuw',
                    '_blank',
                  )
                }
              >
                Visit Saleslights Youtube Academy
              </Button>
            </Container>
          </Box>
          <Container
            className={styles.salesMessage}
            maxWidth="md"
            sx={{ padding: { sm: '0 30px', md: 0 } }}
          >
            <Typography
              variant="h4"
              color="primary.dark"
              align="center"
              fontWeight={700}
            >
              Sales Message Creation Streamlined
            </Typography>
            <Typography variant="body1" align="center">
              {`Do you want to automate sales outreach, streamline the process, and
          maximize ROI? Saleslights is the most cutting-edge Sales Automation
          Software! Saleslights reinvents targeted sales communications. Our
          smart SaaS application analyzes LinkedIn leads' posts, replies,
          profiles, and personalities. This in-depth examination provides
          insights that will change how you approach prospects.`}
            </Typography>
            <Box
              position="relative"
              width={{ xs: 280, sm: 500, md: 600, lg: 760 }}
              height={{ xs: 180, sm: 320, md: 350, lg: 450 }}
            >
              <img
                className={styles.salesMessageInfographic}
                src={salesMessageInfographic}
                alt="sales message infographic"
                //               fill
                //               sizes="(max-width: 768px) 100vw,
                // (max-width: 1200px) 50vw,
                // 33vw"
                //               quality={100}
                //               priority
              />
            </Box>
          </Container>
          <Container className={styles.salesAutomation} maxWidth="lg">
            <Box
              position="absolute"
              width={40}
              height={40}
              sx={{
                top: { xs: '-6%', sm: '-6%', md: '6%', lg: '8%' },
                left: { xs: '-2%', sm: '-1%', md: '20%', lg: '12%' },
              }}
            >
              <img
                src={ellipse1}
                alt="saleslight infographic"
                // fill
                // sizes="(max-width: 768px) 100vw,
                // (max-width: 1200px) 50vw,
                // 33vw"
                // quality={100}
                // priority
              />
            </Box>
            <Grid
              container
              spacing={{ xs: 6, md: 0, lg: 10 }}
              justifyContent={{ xs: 'center', md: 'space-between' }}
              alignItems="center"
            >
              <Grid
                className={styles.salesAutomationInfo}
                item
                xs={12}
                md={5}
                lg={4}
              >
                <Typography
                  variant="h5"
                  fontWeight={500}
                  sx={{ display: { xs: 'none', md: 'block' } }}
                >
                  {`Saleslights' Advanced Sales Automation Software Simplifies Sales`}
                </Typography>
                <Typography
                  variant="h4"
                  color="primary.dark"
                  align="center"
                  fontWeight={700}
                  sx={{ display: { xs: 'block', md: 'none' } }}
                >
                  {`Saleslights' Advanced Sales Automation Software Simplifies Sales`}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: { xs: 'center', md: 'left' } }}
                >
                  {` Saleslights' cutting-edge Sales Automation Software filters your
                Linked leads' posts, comments, responses, and reactions by
                keyword, removing noise. Saleslights Sales Automation gives your
                sales staff a full spectrum of capabilities to promote
                productivity and client engagement.`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
                lg={8}
                mr={{ xs: -4, sm: -6, md: -3, lg: -18 }}
              >
                <Box
                  position="relative"
                  width="100%"
                  height={{ xs: 250, sm: 500, md: 490, lg: 640 }}
                >
                  <img
                    src={salesAutomationInfographic}
                    alt="sales automation infographic"
                    //                   fill
                    //                   sizes="(max-width: 768px) 100vw,
                    // (max-width: 1200px) 50vw,
                    // 33vw"
                    //                   quality={100}
                    //                   priority
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Container
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Button
              className={styles.headerLearnButton}
              variant="contained"
              onClick={() =>
                window.open(
                  'https://chrome.google.com/webstore/detail/saleslights-inc/jllhlcfbocidmmnflpokgemkgfefpmpi',
                  '_blank',
                )
              }
            >
              Download Chrome Extension
            </Button>
          </Container>
          <Box className={styles.scalingRoi}>
            <Box
              position="absolute"
              width={{ xs: 50, sm: 60, md: 60, lg: 70 }}
              height={{ xs: 90, sm: 100, md: 100, lg: 110 }}
              sx={{
                top: { xs: '-2%', sm: '-8%', md: '28%', lg: '24%' },
                right: { xs: '0%', sm: '0%', md: '0%', lg: '0' },
              }}
            >
              <img
                src={group}
                alt="saleslight infographic"
                // fill
                // sizes="(max-width: 768px) 100vw,
                // (max-width: 1200px) 50vw,
                // 33vw"
                // quality={100}
                // priority
              />
            </Box>
            <Box
              position="absolute"
              width={{ xs: 100, sm: 140, md: 160, lg: 210 }}
              height={{ xs: 150, sm: 200, md: 240, lg: 310 }}
              sx={{
                top: { xs: '6%', sm: '-2%', md: '38%', lg: '30%' },
                right: { xs: '0%', sm: '0%', md: '0%', lg: '0' },
              }}
            >
              <img
                src={ellipse4}
                alt="saleslight infographic"
                // fill
                // sizes="(max-width: 768px) 100vw,
                // (max-width: 1200px) 50vw,
                // 33vw"
                // quality={100}
                // priority
              />
            </Box>
            <Container className={styles.scalingRoi} maxWidth="lg">
              <Grid
                container
                spacing={{ xs: 6, md: 0, lg: 10 }}
                justifyContent={{ xs: 'center', md: 'space-between' }}
                alignItems="center"
              >
                <Grid
                  className={styles.scalingRoiInfo}
                  item
                  xs={12}
                  md={5}
                  lg={4}
                  sx={{ order: { xs: 1, md: 2 } }}
                >
                  <Typography
                    variant="h5"
                    fontWeight={500}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  >
                    Scaling and ROI
                  </Typography>
                  <Typography
                    variant="h4"
                    color="primary.dark"
                    align="center"
                    fontWeight={700}
                    sx={{ display: { xs: 'block', md: 'none' } }}
                  >
                    Scaling and ROI
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: { xs: 'center', md: 'left' } }}
                  >
                    Manual research is obsolete. Saleslights frees up your sales
                    team to close deals! Saleslights boosts your business by
                    automating social media post review and sales message
                    creation.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={7}
                  lg={8}
                  ml={{ xs: -4, sm: -6, md: -3, lg: -18 }}
                  sx={{ order: { xs: 2, md: 1 } }}
                >
                  <Box
                    position="relative"
                    width="100%"
                    height={{ xs: 250, sm: 500, md: 490, lg: 640 }}
                  >
                    <img
                      src={scalingRoiInfographic}
                      alt="scaling roi infographic"
                      //                     fill
                      //                     sizes="(max-width: 768px) 100vw,
                      // (max-width: 1200px) 50vw,
                      // 33vw"
                      //                     quality={100}
                      //                     priority
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box className={styles.contextualMessaging}>
            <Box
              position="absolute"
              width={{ xs: 180, sm: 240, md: 220, lg: 250 }}
              height={{ xs: 220, sm: 280, md: 290, lg: 350 }}
              sx={{
                top: { xs: '-8%', sm: '-8%', md: '11%', lg: '12%' },
                left: { xs: '0%', sm: '0%', md: '0%', lg: '0' },
              }}
            >
              <img
                src={ellipse3}
                alt="saleslight infographic"
                // fill
                // sizes="(max-width: 768px) 100vw,
                // (max-width: 1200px) 50vw,
                // 33vw"
                // quality={100}
                // priority
              />
            </Box>
            <Container
              className={styles.contextualMessagingContainer}
              maxWidth="lg"
            >
              <Grid
                container
                spacing={{ xs: 6, md: 0, lg: 10 }}
                justifyContent={{ xs: 'center', md: 'space-between' }}
                alignItems="center"
              >
                <Grid
                  className={styles.contextualMessagingInfo}
                  item
                  xs={12}
                  md={5}
                  lg={4}
                >
                  <Typography
                    variant="h5"
                    fontWeight={500}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  >
                    Easy Contextual Messaging
                  </Typography>
                  <Typography
                    variant="h4"
                    color="primary.dark"
                    align="center"
                    fontWeight={700}
                    sx={{ display: { xs: 'block', md: 'none' } }}
                  >
                    Easy Contextual Messaging
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: { xs: 'center', md: 'left' } }}
                  >
                    Saleslights lets you send prospect-targeted messaging. Our
                    platform optimizes lead engagement and response rates by
                    using user input and our contextualization algorithm to
                    customize sales messaging. Our algorithm uses
                    next-generation Generative AI to build you into the future.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={7}
                  lg={8}
                  mr={{ xs: -4, sm: -6, md: -3, lg: -18 }}
                >
                  <Box
                    position="relative"
                    width="100%"
                    height={{ xs: 250, sm: 500, md: 490, lg: 640 }}
                  >
                    <img
                      src={contextualMessagingInfographic}
                      alt="contextual messaging infographic"
                      //                     fill
                      //                     sizes="(max-width: 768px) 100vw,
                      // (max-width: 1200px) 50vw,
                      // 33vw"
                      //                     quality={100}
                      //                     priority
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Container
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Button
              className={styles.headerLearnButton}
              variant="contained"
              onClick={() =>
                window.open(
                  'https://chrome.google.com/webstore/detail/saleslights-inc/jllhlcfbocidmmnflpokgemkgfefpmpi',
                  '_blank',
                )
              }
            >
              Download Chrome Extension
            </Button>
          </Container>
          <Box className={styles.salesOutreach}>
            <Box
              position="absolute"
              width={{ xs: 60, sm: 70, md: 90, lg: 80 }}
              height={{ xs: 60, sm: 70, md: 90, lg: 80 }}
              sx={{
                top: { xs: '44%', sm: '22%', md: '55%', lg: '58%' },
                right: { xs: '0%', sm: '0%', md: '0%', lg: '0' },
              }}
            >
              <img
                src={group1}
                alt="saleslight infographic"
                // fill
                // sizes="(max-width: 768px) 100vw,
                // (max-width: 1200px) 50vw,
                // 33vw"
                // quality={100}
                // priority
              />
            </Box>
            <Container className={styles.salesOutreactContainer} maxWidth="lg">
              <Grid
                container
                spacing={{ xs: 6, md: 0, lg: 10 }}
                justifyContent={{ xs: 'center', md: 'space-between' }}
                alignItems="center"
              >
                <Grid
                  className={styles.salesOutreachInfo}
                  item
                  xs={12}
                  md={5}
                  lg={4}
                  sx={{ order: { xs: 1, md: 2 } }}
                >
                  <Typography
                    variant="h5"
                    fontWeight={500}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  >
                    Drip Campaign Sales Outreach
                  </Typography>
                  <Typography
                    variant="h4"
                    color="primary.dark"
                    align="center"
                    fontWeight={700}
                    sx={{ display: { xs: 'block', md: 'none' } }}
                  >
                    Drip Campaign Sales Outreach
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: { xs: 'center', md: 'left' } }}
                  >
                    Focus on lead nurturing rather than repetitive outreach
                    chores. Custom drip campaigns automate sales outreach using
                    Saleslights. Automatically send well-timed and targeted
                    messages to your prospects.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={7}
                  lg={8}
                  ml={{ xs: -4, sm: -6, md: -3, lg: -18 }}
                  sx={{ order: { xs: 2, md: 1 } }}
                >
                  <Box
                    position="relative"
                    width="100%"
                    height={{ xs: 250, sm: 500, md: 490, lg: 640 }}
                  >
                    <img
                      src={salesOutreachInfographic}
                      alt="sales outreach infographic"
                      //                     fill
                      //                     sizes="(max-width: 768px) 100vw,
                      // (max-width: 1200px) 50vw,
                      // 33vw"
                      //                     quality={100}
                      //                     priority
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box
            className={styles.whySaleslightSection}
            sx={{ backgroundColor: theme.palette.secondary.main }}
            py={10}
          >
            <Box
              position="absolute"
              width={{ xs: 60, sm: 80, md: 90, lg: 100 }}
              height={{ xs: 80, sm: 100, md: 110, lg: 120 }}
              sx={{
                top: { xs: '2%', sm: '6%', md: '8%', lg: '20%' },
                left: { xs: '80%', sm: '0%' },
              }}
            >
              <img
                src={group2}
                alt="saleslight infographic"
                // fill
                // sizes="(max-width: 768px) 100vw,
                // (max-width: 1200px) 50vw,
                // 33vw"
                // quality={100}
                // priority
              />
            </Box>
            <Box
              position="absolute"
              width={40}
              height={40}
              sx={{
                bottom: { sm: '26%', md: '36%', lg: '34%' },
                left: { sm: '10%', md: '6%', lg: '8%' },
                display: { xs: 'none', sm: 'block' },
              }}
            >
              <img
                src={ellipse1}
                alt="saleslight infographic"
                // fill
                // sizes="(max-width: 768px) 100vw,
                // (max-width: 1200px) 50vw,
                // 33vw"
                // quality={100}
                // priority
              />
            </Box>
            <Box
              position="absolute"
              width={{ xs: 120, sm: 160, md: 150, lg: 260 }}
              height={{ xs: 140, sm: 180, md: 180, lg: 280 }}
              sx={{
                bottom: { xs: '8%', sm: '22%', md: '30%', lg: '24%' },
                right: '0%',
              }}
            >
              <img
                src={ellipse5}
                alt="saleslight infographic"
                // fill
                // sizes="(max-width: 768px) 100vw,
                // (max-width: 1200px) 50vw,
                // 33vw"
                // quality={100}
                // priority
              />
            </Box>
            <Box
              position="absolute"
              width={{ xs: 40, sm: 50, lg: 60 }}
              height={{ xs: 15, sm: 15, lg: 20 }}
              sx={{
                bottom: { xs: '12%', sm: '31%', md: '41%', lg: '42%' },
                right: { xs: '22%', sm: '15%', md: '10%', lg: '14%' },
              }}
            >
              <img
                src={group3}
                alt="saleslight infographic"
                // fill
                // sizes="(max-width: 768px) 100vw,
                // (max-width: 1200px) 50vw,
                // 33vw"
                // quality={100}
                // priority
              />
            </Box>
            <Container className={styles.whySaleslightContainer} maxWidth="lg">
              <Typography
                variant="h4"
                color="primary.dark"
                align="center"
                fontWeight={700}
              >
                Why Saleslights?
              </Typography>

              <Box className={styles.headerContainer} maxWidth="lg">
                <Button
                  className={styles.ytAcademy}
                  variant="contained"
                  onClick={() =>
                    window.open(
                      'https://www.youtube.com/channel/UC1Ax9UOZMmYMnmWENvPwNuw',
                      '_blank',
                    )
                  }
                >
                  Visit Saleslights Youtube Academy
                </Button>
              </Box>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <Box className={styles.whySaleslightItem}>
                    <Box position="relative" width={60} height={45}>
                      <img
                        src={timeEfficientIcon}
                        alt="sales automation infographic"
                        //     fill
                        //     sizes="(max-width: 768px) 100vw,
                        // (max-width: 1200px) 50vw,
                        // 33vw"
                        //     quality={100}
                        //     priority
                      />
                    </Box>
                    <Typography
                      variant="h5"
                      color="primary.dark"
                      fontWeight={500}
                    >
                      Time-Efficient
                    </Typography>
                    <Typography variant="body1" fontWeight={300}>
                      Spend less time researching and writing messages and more
                      time communicating with prospects.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box className={styles.whySaleslightItem}>
                    <Box position="relative" width={60} height={45}>
                      <img
                        src={increaseEfficiencyIcon}
                        alt="sales automation infographic"
                        //     fill
                        //     sizes="(max-width: 768px) 100vw,
                        // (max-width: 1200px) 50vw,
                        // 33vw"
                        //     quality={100}
                        //     priority
                      />
                    </Box>
                    <Typography
                      variant="h5"
                      color="primary.dark"
                      fontWeight={500}
                    >
                      Increased Efficiency
                    </Typography>
                    <Typography variant="body1" fontWeight={300}>
                      {`Our clever software ensures you're always sending the most
                    relevant communications.`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box className={styles.whySaleslightItem}>
                    <Box position="relative" width={60} height={45}>
                      <img
                        src={personalizeIcon}
                        alt="sales automation infographic"
                        //     fill
                        //     sizes="(max-width: 768px) 100vw,
                        // (max-width: 1200px) 50vw,
                        // 33vw"
                        //     quality={100}
                        //     priority
                      />
                    </Box>
                    <Typography
                      variant="h5"
                      color="primary.dark"
                      fontWeight={500}
                    >
                      {`Personalize your outreach by learning about your leads'
                    interests.`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box className={styles.whySaleslightItem}>
                    <Box position="relative" width={60} height={45}>
                      <img
                        src={consistencyCommunicationIcon}
                        alt="sales automation infographic"
                        //     fill
                        //     sizes="(max-width: 768px) 100vw,
                        // (max-width: 1200px) 50vw,
                        // 33vw"
                        //     quality={100}
                        //     priority
                      />
                    </Box>
                    <Typography
                      variant="h5"
                      color="primary.dark"
                      fontWeight={500}
                    >
                      Consistency in Communication
                    </Typography>
                    <Typography variant="body1" fontWeight={300}>
                      Maintain brand voice consistency across platforms and team
                      members.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box className={styles.whySaleslightItem}>
                    <Box position="relative" width={60} height={45}>
                      <img
                        src={timeEfficientIcon}
                        alt="sales automation infographic"
                        //     fill
                        //     sizes="(max-width: 768px) 100vw,
                        // (max-width: 1200px) 50vw,
                        // 33vw"
                        //     quality={100}
                        //     priority
                      />
                    </Box>
                    <Typography
                      variant="h5"
                      color="primary.dark"
                      fontWeight={500}
                    >
                      Data-Driven Decisions
                    </Typography>
                    <Typography variant="body1" fontWeight={300}>
                      Use data to improve sales performance and strategy.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Container
            className={styles.trySaleslight}
            maxWidth="md"
            sx={{ padding: { sm: '0 30px', md: 0 }, mt: -24 }}
          >
            <Typography
              variant="h4"
              color="primary.dark"
              align="center"
              fontWeight={700}
            >
              Try Saleslights Today for Better Sales Success!
            </Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{ padding: { xs: 0, sm: '0 5rem' } }}
            >
              Try Saleslights for free today. Increase sales automation and see
              your sales process change. Saleslights boosts ROI, conversions,
              and customer relationships. Ready to achieve sales excellence?
              Sales automation and the future is here!
            </Typography>
            <Link to="/signup">
              <Button className={styles.getStartedButton} variant="contained">
                {`GET STARTED - IT'S FREE`}
              </Button>
            </Link>
          </Container>
          <ContactSection />
        </Box>
      </MainLayout>
    );
  }
}

// return (
// );
// };

export default LandingPage;
