import { useState } from 'react';
import LoginSection from '../../components/login-section/LoginSection';
import MainLayout from '../../components/main-layout/MainLayout';
import styles from './Login.module.scss';
import { Box } from '@mui/material';

const Login = () => {
  const [ isLoggedIn, setIsLoggedIn ] = useState(false);
  return (
    <MainLayout title="SalesLights | Get Started - It's Free" isLoggedIn={isLoggedIn}>
      <Box className={styles.main}>
        <LoginSection setIsLoggedIn={(bool) => setIsLoggedIn(bool)} />
      </Box>
    </MainLayout>
  );
};

export default Login;
