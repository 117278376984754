import PricingCheckoutSuccessSection from '../../../../components/pricing-section/PricingCheckoutSuccessSection';
import MainLayout from '../../../../components/main-layout/MainLayout';
import styles from './PricingCheckoutSuccess.module.scss';
import { Box } from '@mui/material';
import React from 'react';
import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

class PricingCheckoutSuccess extends React.Component {
  constructor(props) {
    super(props);

    this.routes = localStorage.getItem('routes');
    this.planId = JSON.parse(this.routes).planId;
    this.licenseType = JSON.parse(this.routes).planName;

    this.routes = localStorage.getItem('user');
    this.email = JSON.parse(this.routes).email;
  }

  componentDidMount = () => {
    // Get the routers localStorage
    // nextRoute
    // plan
    // email
    const currentUrl = window.location.href;
    const urlSearchParams = new URLSearchParams(new URL(currentUrl).search);
    const sessionId = urlSearchParams.get('session_id');
    const updatePlanItem = {
      email: this.email,
      license_type: this.licenseType,
      session_id: sessionId,
    };
    axios
      .post(`${API_URL}/pricing/update-plan/`, updatePlanItem)
      .then((response) => {
        if (response.status === 200) {
          window.open(response.data.url);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <MainLayout title="SalesLights | Get Started - It's Free">
        <Box className={styles.main}>
          <PricingCheckoutSuccessSection />
        </Box>
      </MainLayout>
    );
  }
}

export default PricingCheckoutSuccess;
