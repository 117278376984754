import SignupSection from '../../components/signup-section/SignupSection';
import MainLayout from '../../components/main-layout/MainLayout';
import styles from './Signup.module.scss';
import { Box } from '@mui/material';

const Signup = () => {
  return (
    <MainLayout title="SalesLights | Get Started - It's Free">
      <Box className={styles.main}>
        <SignupSection />
      </Box>
    </MainLayout>
  );
};

export default Signup;
