import SignupSuccessSection from '../../../components/signup-section/signup-success-section/SignupSuccessSection';
import MainLayout from '../../../components/main-layout/MainLayout';
import styles from './SignupSuccess.module.scss';
import { Box } from '@mui/material';

const SignupSuccess = () => {
  return (
    <MainLayout title="SalesLights | Get Started - It's Free">
      <Box className={styles.main}>
        <SignupSuccessSection />
      </Box>
    </MainLayout>
  );
};

export default SignupSuccess;
