import { useState } from 'react';
import {
  Box,
  Button,
  InputLabel,
  TextField,
  Collapse,
  Alert,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './ContactForm.module.scss';
import axios from 'axios';
import { API_URL } from '../../utils/constants';

const ContactForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstNameError, setFirstNameError] = useState(' ');
  const [lastNameError, setLastNameError] = useState(' ');
  const [emailError, setEmailError] = useState(' ');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const emailRegex = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);

  const submitForm = async () => {
    setFirstNameError(' ');
    setLastNameError(' ');
    setEmailError(' ');
    setIsSubmitting(true);
    setShowAlert(false);
    setAlertSeverity('success');
    setAlertMessage('');

    if (firstName === '') {
      setFirstNameError('First name is required!');
    }

    if (lastName === '') {
      setLastNameError('Last name is required!');
    }

    if (email === '') {
      setEmailError('Email is required!');
    } else if (!emailRegex.test(email)) {
      setEmailError('Email is not valid!');
    }

    if (
      firstName === '' ||
      lastName === '' ||
      email === '' ||
      !emailRegex.test(email)
    ) {
      setIsSubmitting(false);
      return;
    }

    try {
      const data = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber,
        source: 'website',
      };

      await axios({
        url: `${API_URL}/crm/createLead`,
        method: 'POST',
        data: data,
      })
        .then((response) => {
          if (response.data.status === 'OK') {
            setShowAlert(true);
            setAlertSeverity('success');
            setAlertMessage(
              'Submission is successful. Will respond to you soon!',
            );
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhoneNumber('');
          }
        })
        .catch((error) => {
          setShowAlert(true);
          setAlertSeverity('error');
          if (error.response.status === 409)
            setAlertMessage(
              'The email you provided has already been sent to be processed.',
            );
          else
            setAlertMessage(
              'There was a problem with submitting your details. Please try again later.',
            );
        });
    } catch (error) {
      setShowAlert(true);
      setAlertSeverity('error');
      setAlertMessage(
        'There was a problem with submitting your details. Please try again later.',
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box className={styles.contactForm}>
      <Collapse in={showAlert}>
        <Alert
          severity={alertSeverity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setShowAlert(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {alertMessage}
        </Alert>
      </Collapse>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        <Box>
          <InputLabel shrink htmlFor="firstname">
            First Name*
          </InputLabel>
          <TextField
            id="firstname"
            variant="outlined"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={firstNameError !== ' '}
            helperText={firstNameError}
          />
        </Box>
        <Box>
          <InputLabel shrink htmlFor="lastname">
            Last Name*
          </InputLabel>
          <TextField
            id="lastname"
            variant="outlined"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={lastNameError !== ' '}
            helperText={lastNameError}
          />
        </Box>
      </Box>
      <Box>
        <InputLabel shrink htmlFor="email">
          Email Address*
        </InputLabel>
        <TextField
          id="email"
          variant="outlined"
          placeholder="Email Address"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={emailError !== ' '}
          helperText={emailError}
        />
      </Box>
      <Box>
        <InputLabel shrink htmlFor="phone">
          Phone Number
        </InputLabel>
        <TextField
          id="phone"
          variant="outlined"
          placeholder="Phone Number"
          fullWidth
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </Box>
      <Button
        className={styles.contactButton}
        sx={{ width: { xs: '100%', sm: '226px' } }}
        variant="contained"
        fullWidth
        onClick={submitForm}
        disabled={isSubmitting}
      >
        SUBMIT
      </Button>
    </Box>
  );
};

export default ContactForm;
