import ContactSection from '../../components/contact-section/ContactSection';
import MainLayout from '../../components/main-layout/MainLayout';
import styles from './ContactUs.module.scss';
import { Box } from '@mui/material';

const ContactUs = () => {
  return (
    <MainLayout title="SalesLights | Contact Us">
      <Box className={styles.main}>
        <ContactSection />
      </Box>
    </MainLayout>
  );
};

export default ContactUs;
