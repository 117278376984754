import MainLayout from '../../components/main-layout/MainLayout';
import styles from './CookiesPolicy.module.scss';
import { Box } from '@mui/material';

const CookiesPolicy = () => {
  return (
    <>
      <MainLayout title="SalesLights | Contact Us">
        <Box className={styles.main}>
          <div className={styles.container}>
            <h1>Cookies Policy</h1>
            <h2>Effective Date: [09-27-2023]</h2>
            <ul>
              <li>
                <h2>1. Introduction</h2>
                <p>
                  Welcome to the Cookies Policy of Saleslights Inc.
                  ("Saleslights," "we," "our," or "us"). This policy outlines
                  our approach to the use of cookies and similar technologies on
                  our website, www.saleslights.com ("Website"). By accessing or
                  using our website, you consent to the use of cookies as
                  described in this policy.{' '}
                </p>
              </li>
              <li>
                <h2>2. Definitions</h2>
                <ul>
                  <li>
                    - Cookies: Small text files that are placed on your device
                    by a website when you visit. These files may contain
                    information such as your preferences, unique identifiers,
                    and other usage data.
                  </li>
                  <li>
                    -GDPR: General Data Protection Regulation, the European
                    Union regulation that governs the processing of personal
                    data and the protection of individuals' privacy rights.
                  </li>
                  <li>- User: Anyone who accesses or uses our website.</li>
                  <li>
                    - State of Delaware: The jurisdiction in which Saleslights
                    Inc. is registered and operates.
                  </li>
                </ul>
              </li>
              <li>
                <h2>3. Types of Cookies Used </h2>
                <p>We use different types of cookies for various purposes: </p>
                <ul>
                  <li>
                    - Essential Cookies: These cookies are necessary for the
                    proper functioning of our Website. They enable you to
                    navigate around the site and use its features.
                  </li>
                  <li>
                    - Analytics Cookies: We use analytics cookies to gather
                    information about how our Website is used. This information
                    helps us improve our services and provide a better user
                    experience.
                  </li>
                  <li>- Sending you promotional offers and updates.</li>
                  <li>
                    - Marketing Cookies: These cookies track your online
                    activity to provide you with personalized advertisements and
                    marketing materials based on your interests.
                  </li>
                </ul>
              </li>
              <li>
                <h2>4. Use of Cookies</h2>
                <p>Saleslights Inc. uses cookies to:</p>
                <ul>
                  <li>- Improve and optimize your browsing experience.</li>
                  <li>
                    - Analyze trends and gather statistical data to enhance our
                    services.
                  </li>
                  <li>
                    - Provide relevant advertisements and marketing materials.
                  </li>
                  <li>
                    - Facilitate seamless navigation and access to specific
                    content.
                  </li>
                </ul>
              </li>
              <li>
                <h2>5. Sharing of Data</h2>
                <p>
                  We may share certain data collected through cookies with third
                  parties, including service providers, advertisers, and
                  business partners. This data sharing is conducted within the
                  legal framework of the State of Delaware and GDPR. The shared
                  data does not include personally identifiable information
                  unless explicitly provided by you.{' '}
                </p>
              </li>
              <li>
                <h2>6. Your Choices</h2>
                <p>You have the right to:</p>
                <ul>
                  <li>
                    - Opt-Out: You can choose to disable cookies through your
                    browser settings or using opt-out mechanisms provided by
                    third-party advertisers.{' '}
                  </li>
                  <li>
                    - Personal Data: For information about your personal data
                    rights and choices, please refer to our Privacy Policy.{' '}
                  </li>
                </ul>
              </li>
              <li>
                <h2>7. Contact Us</h2>
                <p>
                  If you have any questions or concerns about our Cookies Policy
                  or the use of cookies on our Website, please contact us:
                </p>
                <ul>
                  <li>Saleslights Inc.</li>
                  <li>99 Wall Street #240</li>
                  <li>New York, NY 10005</li>
                  <li>Email: info@saleslights.com </li>
                </ul>
              </li>
              <li>
                <h2>8. Changes to this Privacy Policy</h2>
                <p>
                  Saleslights Inc. reserves the right to modify or update this
                  Cookies Policy at any time. We will provide notice of any
                  significant changes through our website or other appropriate
                  communication channels.
                </p>
                <p>
                  By using our website after changes to this policy have been
                  posted, you agree to the updated policy.{' '}
                </p>
                <p>This Cookies Policy was last updated on [09-27-2023]. </p>
              </li>
            </ul>
          </div>
        </Box>
      </MainLayout>
    </>
  );
};

export default CookiesPolicy;
