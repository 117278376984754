import {
  Route,
} from 'react-router-dom';

import { BrowserRouter, Routes } from 'react-router-dom';
import LandingPage from './pages/landing-page/LandingPage';
import ContactUs from './pages/contact-us/ContactUs';
import './stylesheets/app.scss';
import TOC from './pages/toc/TOC';
import EULA from './pages/eula/EULA';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import CookiesPolicy from './pages/cookies-policy/CookiesPolicy';
import Pricing from './pages/pricing/Pricing';
import BasicPlanPricing from './pages/pricing/basic-plan/BasicPlanPricing';
import ProPlanPricing from './pages/pricing/pro-plan/ProPlanPricing';
import ElitePlanPricing from './pages/pricing/elite-plan/ElitePlanPricing';
import Signup from './pages/signup/Signup';
import SignUpSuccess from './pages/signup/signup-success/SignupSuccess';
import PricingCheckoutSuccess from './pages/pricing/checkout/success/PricingCheckoutSuccess';
import Login from './pages/login/Login';

const App = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="terms-and-conditions" element={<TOC />} />
        <Route path="end-user-license-agreement" element={<EULA />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="cookies-policy" element={<CookiesPolicy />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="pricing/basic-plan" element={<BasicPlanPricing />} />
        <Route path="pricing/pro-plan" element={<ProPlanPricing />} />
        <Route path="pricing/elite-plan" element={<ElitePlanPricing />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup/signup-success" element={<SignUpSuccess />} />
        <Route
          path="/pricing/checkout-success"
          element={<PricingCheckoutSuccess />}
          exact
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
